import { Outlet } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import "./theme.css";
import React from "react";
import { useLocation , useNavigate} from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/theme";

function App() {
  const location = useLocation(); 
  const navigate = useNavigate()
  const isLoggedIn =location.pathname !== "/login";

  React.useEffect(() =>{
    if(location.pathname === '/'){
      navigate("/project-list")
    }
  } , [navigate , location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <div className="App" >
        <Header  isLoggedIn ={isLoggedIn}/>
        <Outlet/>
      </div>
    </ThemeProvider>
  );
}

export default App;

