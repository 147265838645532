import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the type for your state
interface AllContextStoryProps {
  [key: string]: any[]; // Value is an array of any type of object
}

// Define the initial state
const initialState: AllContextStoryProps = {};

// Create the slice
const AllContextStory = createSlice({
  name: "AllContextStory",
  initialState,
  reducers: {
    // Add a reducer to update the state for a specific key
    updateData(state, action: PayloadAction<{ key: string; value: any[] }>) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    addDataToKey(state, action: PayloadAction<{ key: string; newData: any }>) {
      const { key, newData } = action.payload;
      if (state[key]) {
        state[key].push(newData); // Add new data to the existing array
      } else {
        state[key] = [newData]; // If the key does not exist, create a new array with the new data
      }
    },
  },
});

// Extract the action creators
export const { updateData, addDataToKey } = AllContextStory.actions;

// Export the reducer
export default AllContextStory.reducer;
