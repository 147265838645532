import React from "react";
import "../../theme.css";
import Loader from "../../components/Loader/Loader";
import NoData from "../../assets/NoDataSvg.svg";
import { useNavigate } from "react-router-dom";
import { getAllProject } from "../../api/ProjectAPIs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useSocket from "../../hooks/useSocket";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  resetUpdatedList,
  updatedList,
} from "../../store/Slices/MultiUserUpdate";
import { RootState } from "../../store/store";

const ProjectList: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [projectList, setProjectList] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const isCreatedProject = useSelector(
    (state: any) => state.ProjectSlice.newProjectCreated,
  );
  const { t } = useTranslation();
  const { data: dataMessages } = useSocket({ topic: "update_project_member" });

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { updatedMemberlist } = useSelector(
    (state: RootState) => state.MultiUserSlice,
  );

  React.useEffect(() => {
    // TODO: handle by command type
    console.log("updated in prohect list", dataMessages);
    if (!dataMessages) {
      return;
    }
    if (dataMessages) {
      const messageLength: [] = dataMessages.members;
      if (updatedMemberlist.length === messageLength.length) {
        dispatch(resetUpdatedList());
      } else dispatch(updatedList(dataMessages.members));
    }
    // eslint-disable-next-line
  }, [dataMessages, dispatch]);

  React.useEffect(() => {
    const fetchAllProject = async () => {
      try {
        const response = await getAllProject();
        setLoading(false);
        setProjectList(response.body);
      } catch (error: any) {
        console.log(`Error Fetching Project ${error.message}`);
      }
    };
    fetchAllProject();
  }, [isCreatedProject, updatedMemberlist]);

  const handleOpenProject = (id: string) => {
    navigate(`/project/${id}`);
  };

  return (
    <div className="project-list" data-testid="project-list">
      {loading ? (
        <Loader />
      ) : projectList.length !== 0 ? (
        projectList.map((project: any) => {
          return (
            <div
              className="project"
              key={project.id}
              onClick={() => handleOpenProject(project.id)}
            >
              {project.name}
            </div>
          );
        })
      ) : (
        <div className="project">
          <img src={NoData} alt="" />
          <p>{t("ProjectNotFound")}</p>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
