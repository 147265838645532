import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/Context.css";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import StoryList from "../StoryList";
import StoryComp from "../StoryComp/StoryComp";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateData } from "../../store/Slices/AllContextStoryData";
import { toast } from "react-toastify";
import { getStoryInProjectContext } from "../../api/ProjectAPIs";
import { RootState } from "../../store/store";
import { searchKeyInUpdatedData } from "../../utils/CommonFunction";

interface ContextProps {
  label: string;
  getContextStory: string;
  icon?: string;
  onClose?: () => void;
  storyModifying?: {
    storyId: string;
    userId: string;
  };
}

function Context({
  label,
  getContextStory,
  icon,
  storyModifying,
  onClose,
}: ContextProps) {
  const [openCreateStory, setOpenCreateStory] = React.useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const count = useSelector((state: any) => state.CreateStorySlice.counter);
  const dispatch = useDispatch();
  const AllContextStoryData = useSelector(
    (state: RootState) => state.AllContextStory,
  );

  React.useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await getStoryInProjectContext(id!, getContextStory);
        dispatch(updateData({ key: getContextStory, value: response.body }));
      } catch (error) {
        console.log(`Error fetching stroy in context ${error}`);
        toast.error("Error Fetching Stories");
      }
    };

    fetchStories();
  }, [id, getContextStory, count, dispatch]);

  const storiesData = React.useMemo(() => {
    return searchKeyInUpdatedData(AllContextStoryData, getContextStory);
  }, [getContextStory, AllContextStoryData]);

  const getIcon = (icon?: string) => {
    if (!icon) return null;
    // load the image at /assets/contexts/<icon>.png
    return (
      <img
        src={require(`../../../assets/contexts/${icon}.png`)}
        alt={icon}
        width="30"
        height="30"
      />
    );
  };

  return (
    <div
      className="Context_Main"
      style={{ width: "100%", height: "100%" }}
      data-testid="Context-ID"
    >
      <div className="Context_Header">
        <h3>
          {getIcon(icon)} {t(`${label}`)}
        </h3>
        <div className="Context_Header_buttons">
          <Button
            label={t("Add")}
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateStory(!openCreateStory)}
          />
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" sx={{ color: "white" }} />
          </IconButton>
        </div>
      </div>
      <div className="Context_Stories">
        {openCreateStory && (
          <StoryComp
            handleClose={() => setOpenCreateStory(false)}
            getContextStory={getContextStory}
            stories={storiesData}
          />
        )}
        {storiesData.length !== 0 && (
          <StoryList
            storyModifying={storyModifying}
            getContextStory={getContextStory}
            stories={storiesData}
          />
        )}
      </div>
    </div>
  );
}
export default Context;
