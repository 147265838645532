import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingOverlay from "../Loader/LoadingOverlay";
import "./Importstories.css";
import { DialogActions, DialogContent } from "@mui/material";
import { InputLabelFunction } from "../../pages/LoginPage/LoginPage";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import DialogBox from "../DialogBox/DialogBox";
import { importProject } from "../../api/ProjectAPIs";
import { useDispatch } from "react-redux";
import { projectCreated } from "../../store/Slices/ProjectSlice";

const ImportStories = () => {
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [files, setFile] = React.useState<File | null>();
  const dispatch = useDispatch();
  // const dialogRef = useRef<HTMLDivElement>(null);
  const [projectName, setProjectName] = React.useState<string>("");

  const handleProjectInput = (value: string) => {
    setProjectName(value);
  };

  const handleOnchange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size <= 0) {
      return toast.error("Invalid file");
    }
    setFile(file);
    setDialogOpen(true);
  };

  const handleProjectCreate = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("uploads", files!);
      formData.append("name", projectName);
      await importProject(formData);
      setLoading(false);
      setDialogOpen(false);
      dispatch(projectCreated());
      toast.success("successfully imported");
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <input
        hidden
        ref={uploadRef}
        type="file"
        onChange={(e) => handleOnchange(e)}
        accept="text/csv, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />

      <div
        onClick={() => uploadRef?.current?.click()}
        // className="border-2 hover:border-black-dark flex-shrink-0 focus:outline-2 flex items-center justify-center duration-200"
        className="Import-Button"
      >
        {t("Import")}
      </div>
      <LoadingOverlay isLoading={loading} />
      {dialogOpen && (
        <DialogBox
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          title="createProject"
        >
          <DialogContent dividers>
            <InputLabelFunction
              label={t("projectName")}
              type="text"
              handleInputData={handleProjectInput}
            />
          </DialogContent>
          <DialogActions>
            <Button
              label="Submit"
              isPrimary={true}
              onClick={handleProjectCreate}
            />
          </DialogActions>
        </DialogBox>
      )}
    </>
  );
};

export default ImportStories;
