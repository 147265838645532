import React from "react";
import { DialogContent, DialogActions } from "@mui/material";
import { InputLabelFunction } from "../../pages/LoginPage/LoginPage";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import getApiKey, { BASE_URL } from "../../config/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { projectCreated } from "../../store/Slices/ProjectSlice";

interface CreateProjectProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CreateProject({ setDialogOpen }: CreateProjectProps) {
  const dispatch = useDispatch();

  const [projectName, setProjectName] = React.useState<String>("");
  const { t } = useTranslation();

  const key = getApiKey();

  const handleProjectInput = (value: string) => {
    setProjectName(value);
  };

  const handleProjectCreate = async () => {
    await axios
      .post(
        `${BASE_URL}/project/create`,
        {
          name: projectName,
        },
        {
          headers: {
            "X-API-KEY": key.API_KEY,
          },
        },
      )
      .then(() => {
        setDialogOpen(false);
        setProjectName("");
        dispatch(projectCreated());
        toast.success("Project Created Successfully");
      })
      .catch((err: any) => {
        console.log(`Error in Creating New Project ${err}`);
        toast.error(`${err.response.data.message}`);
      });
  };

  return (
    <div>
      <DialogContent dividers>
        <InputLabelFunction
          label={t("projectName")}
          type="text"
          handleInputData={handleProjectInput}
        />
      </DialogContent>
      <DialogActions>
        <Button label="Submit" isPrimary={true} onClick={handleProjectCreate} />
      </DialogActions>
    </div>
  );
}

export default CreateProject;
