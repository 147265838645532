import React, { useState } from "react";
import "../../src/styles/Estimate.css";
import { StoryInterface } from "../interface/StoryInterface";
import Estimate from "./Estimate";
import axios from "axios";
import getApiKey, { BASE_URL } from "../config/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { storyCreated } from "../store/Slices/UpdateStorySlice";

interface EstimateSelectorProps {
  range: number;
  story: StoryInterface;
}

const EstimateSelector: React.FC<EstimateSelectorProps> = ({
  range,
  story,
}) => {
  const [insertionPoint, setInsertionPoint] = useState<number | null>(null);
  const [removeEstimationSelector, setRemoveEstimationSelector] = React.useState<string>("")
  const dispatch = useDispatch()

  const handleClick = async (index: number, e : React.MouseEvent<HTMLDivElement>) => {
    setInsertionPoint(index + 1);
    const getApi = getApiKey();
    e.stopPropagation() ; 
    const apiKey = getApi?.API_KEY;
    await axios.patch(`${BASE_URL}/story/${story.id}`, {
      estimate: insertionPoint
    }, {
      headers: {
        "X-API-KEY": apiKey
      }
    }).then((response: any) => {
      setRemoveEstimationSelector("RemoveEstimationSelector")
      toast.success("Points upated successfully")
      dispatch(storyCreated())
    }).catch((error: any) => {
      console.log(`Error updating story point ${error}`)
      toast.error("Cannot Update Points")
    })
  }

  return (
    <>
      <div className={`estimate ${removeEstimationSelector}`} onMouseLeave={() => removeEstimationSelector === "" && setInsertionPoint(null)}>
        {Array.from({ length: range }, (_, index) => (
          <div
            key={index}
            className="estimate-bar"
            style={{
              backgroundColor:
                insertionPoint !== null && index + 1 <= insertionPoint ? 'green' : '#ddd',
              marginTop: '2px',
              marginRight: '1px',
            }}
            onMouseEnter={() => setInsertionPoint(index + 1)}
            onClick={(e : React.MouseEvent<HTMLDivElement>) => {
              handleClick(index , e)
            }}
          > </div>
        ))}
      </div>
      {
        removeEstimationSelector !== "" && <Estimate value={insertionPoint !== null ? insertionPoint : 0} range={range} />
      }
    </>
  );
};

export default EstimateSelector;
