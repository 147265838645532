// import axios from "axios";
// import { Config } from "./_config";
// import getApiKey from "../config/config";

// const axiosClient = axios.create({
//   baseURL: Config.baseURL,
//   //   withCredentials: true,
// });

// const key = getApiKey();

// axiosClient.interceptors.request.use(
//   (config) => {
//     config.headers["X-API-KEY"] = key.API_KEY;

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

// axiosClient.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (error) => {
//     const status = error.response?.status;

//     if (error.response && status === 401) {
//       sessionStorage.clear();
//       setTimeout(() => {
//         window.location.pathname = "/login";
//       }, 500);
//     }

//     return Promise.reject(error);
//   },
// );

// export default axiosClient;

import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

let axiosClient: AxiosInstance; // Initialize with null

export const initializeAxios = (
  baseURL: string | undefined,
  apiKey: string,
): AxiosInstance => {
  axiosClient = axios.create({
    baseURL,
    // withCredentials: true,
  });

  axiosClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      config.headers["X-API-KEY"] = apiKey;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosClient.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    async (error) => {
      const status = error.response?.status;

      if (error.response && status === 403) {
        setTimeout(() => {
          window.location.pathname = "/project-list";
        }, 500);
        return axiosClient;
      }

      if (error.response && status === 401) {
        sessionStorage.clear();
        setTimeout(() => {
          window.location.pathname = "/login";
        }, 500);
      }

      return Promise.reject(error);
    },
  );

  return axiosClient;
};

// export default axiosClient;
