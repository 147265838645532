import "../theme.css";
// getCssVariableValue.ts

export const getCssVariableValue = (variableName: string): string | null => {
    if (typeof window !== 'undefined') {
      const value = getComputedStyle(document.documentElement).getPropertyValue(variableName);
      console.debug(`Loaded ${variableName}: ${value}`); // Log the variable name and its value
      return value.trim();
    }
    return null;
  };
  