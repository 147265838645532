import React from "react";
import "../../styles/UserMenu.css";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import axios from "axios";
import getApiKey, { BASE_URL } from "../../config/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserMenu: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation() ; 

  const handleLogout = async () => {
    try {
      const { API_KEY, UserId } = getApiKey();
      await axios.post(
        `${BASE_URL}/auth/logout/${UserId}`,
        {}, 
        {
          headers: {
            "X-API-KEY": API_KEY,
          },
        },
      );
      navigate("/login");
      sessionStorage.removeItem("token");
    } catch (error: any) {
      console.error("Logout Error:", error);
      toast.warn("Naîve Logout attempt failed, but we logged you out anyway.");
      sessionStorage.removeItem("token")
      navigate("/login")
    }
  };

  return (
    <div className="UserMenu" data-testid="user-menu">
      <div className="UserMenu_Profile" onClick={handleLogout}>
        <LogoutRoundedIcon fontSize="small" className="" />
        <p> {t("signout") }</p>
      </div>
    </div>
  );
};

export default UserMenu;
