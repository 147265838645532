import axios from "axios";
import { toast } from "react-toastify";

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isValidStoryCreateData(object: any) {
  if (!object.name) {
    toast.error("Title is required");
    return false;
  } else if (!object.type) {
    toast.error("Story Type is required");
    return false;
  }
  return true;
}

export const getInitials = (name: string) => {
  return name?.substring(0, 2).toUpperCase();
};

export const downloadImage = async (url: string, original_name: string) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob", // Important for downloading files
    });
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", original_name); // You can set the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    toast.error("Error downloading the image");
  }
};

export const downloadFile = (
  url: string,
  filename: string,
  mimeType: string,
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.type = mimeType;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
};
