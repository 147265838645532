import React from 'react';
import "../../src/styles/Estimate.css";

interface EstimateProps {
  value: number;
  range: number;
}

const Estimate: React.FC<EstimateProps> = ({ value, range }) => {
  return (
    <div className="estimate">
      {Array.from({ length: range }, (_, index) => {
        return < div className='estimate-bar'
          key={index}
          style={{
            backgroundColor: range - value >= range - index ? '#ddd' : 'green',
            marginTop: '2px',
            marginRight: '1px',
          }}
        ></div>
      })}
    </div >
  );
};

export default Estimate;
