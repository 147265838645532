import { createSlice } from "@reduxjs/toolkit";

interface MultiUserUpdate {
  updatedMemberlist: [];
}

const initialState: MultiUserUpdate = {
  updatedMemberlist: [],
};

const MultiUserSlice = createSlice({
  name: "MultiUser",
  initialState,
  reducers: {
    updatedList: (state, actions) => {
      state.updatedMemberlist = actions.payload;
    },
    resetUpdatedList: (state) => {
      state.updatedMemberlist = [];
    },
  },
});

export const { updatedList, resetUpdatedList } = MultiUserSlice.actions;
export default MultiUserSlice.reducer;
