import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {  Dialog, DialogTitle, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../styles/CreateProject.css";


interface DialogBoxProps {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
}

export default function DialogBox({
  dialogOpen,
  setDialogOpen,
  title,
  children,
}: DialogBoxProps) {
  const handleClose = () => {
    setDialogOpen(false);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog  onClose={handleClose} aria-labelledby={title} open={dialogOpen}>
        <DialogTitle
          id={title}
          data-testid="Dialog-Id"
          sx={{ fontFamily: "Lexend", p: 2 }}
        >
          {t(`${title}`)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Dialog>
    </React.Fragment>
  );
}
