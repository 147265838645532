import getApiKey from "../config/config";
import { Config } from "./_config";
import { initializeAxios } from "./_main";
const { storage } = Config;

export const uploadFile = (data: { files?: File[] }) => {
  const path = storage.root + storage.uploads;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      const formData = new FormData();
      if (data.files) {
        data.files.forEach((file, index) => {
          formData.append(`uploads`, file, file.name);
        });
      }

      axiosClient
        .post(path, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "file uploaded successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};
