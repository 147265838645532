import React, { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import getApiKey, { BASE_URL } from "../config/config";

const useFetchContext = () => {
  const [fetchContext, setFetchContext] = React.useState<any>([]);
  useEffect(() => {
    const fetchContext = async () => {
      try {
        const getApi = getApiKey();
        const apiKey = getApi?.API_KEY;
        const response = await axios.get(`${BASE_URL}/context`, {
          headers: {
            "X-API-KEY": apiKey,
          },
        });
        const modifiedData = response.data.map((obj: any) => {
          // Add your desired key-value pair to each object
          return { ...obj, selected: false }; // Modify 'newKey' and 'newValue' as needed
        });
        setFetchContext(modifiedData);
      } catch (error: any) {
        toast.error("Cannot fetch context");
      }
    };

    fetchContext();
  }, []);

  return [fetchContext, setFetchContext];
};

export default useFetchContext;
