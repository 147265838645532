import React from "react";
// import StoryModel from "../models/StoryModel";
import { Box } from "@mui/material";
import Estimate from "./Estimate";
import EstimateSelector from "./EstimateSelector";
import WorkflowWidget from "./WorkflowWidget";
// import JSON from 'json5';
import { StoryInterface } from "../interface/StoryInterface";
import { Workflow } from "../interface/WorkflowInterface";
import "../styles/Story.css"

export interface StoryProps {
  story: StoryInterface;
  range?: number;
  workflowData: Workflow[] | null;
  onEdit?: (story: StoryInterface) => void;
  onDeleteOpen?: () => void;
  isDeleteOpen?: boolean;
  deleteChecked?: boolean;
  setDeleteChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

//TODO: create a new branch later for delete story before pushing
const Story: React.FC<StoryProps> = (props) => {
  const { story, range = 8, onEdit } = props;
  const doneClass = story && story!.status?.is_done ? "done" : "";

  return (
    <Box
      onClick={() => {
        onEdit && onEdit(story);
      }}
      className={`story ${story?.type} ${story?.status?.name?.toLocaleLowerCase()} ${doneClass}`}
      data-testid="story"
    >
      <div className="icon"></div>
      <div className="StoryMainTitle">
        <div className="content">
          <p style={{overflow:"hidden", textOverflow:"ellipsis" }}>{story?.name}</p>
        </div>
        <div className="StoryMainEstimate">
          {story?.type === "feature" ? (
            story?.estimate !== null ? (
              <Estimate value={story.estimate!} range={range} />
            ) : (
              <EstimateSelector range={range} story={story} />
            )
          ) : null}
          {props.workflowData?.length !== 0 && (
            <WorkflowWidget story={story} workflowData={props.workflowData} />
          )}
        </div>
      </div>
    </Box>
  );
};

export default Story;
