import axios from "axios";
import { useState } from "react";
import { BASE_URL } from "./../config/config";

export const useRegister = () => {
  const [loading, setLoading] = useState(false);

  const register = async (
    email: string,
    password: string,
    login: string,
    given_name: string,
    family_name: string,
  ) => {
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/auth/register`, {
        email,
        password,
        login,
        given_name,
        family_name,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { register, loading };
};
