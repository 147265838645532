import { createSlice } from "@reduxjs/toolkit";
import { Member } from "../../interface/ProjectInterface";

interface ProjectStateProps {
    newProjectCreated : boolean;
    projectMember : Member[] | null
}

const initialState : ProjectStateProps = {
    newProjectCreated : false, 
    projectMember : null
}

const ProjectSlice = createSlice({
    name: "Project" ,
    initialState,
    reducers : {
        projectCreated : (state) =>{
            state.newProjectCreated = true
        },
        resetProjectCreated : (state) =>{   
            state.newProjectCreated = false  
        },
        ProjectMembers : (state , action)  =>{
            state.projectMember = action.payload
        }
    }
})

export const {projectCreated, resetProjectCreated, ProjectMembers} = ProjectSlice.actions
export default ProjectSlice.reducer 