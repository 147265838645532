import React from "react";
import "../../styles/TextArea.css"; // Import CSS file for styling

interface TextareaProps {
  placeholder: string;
  onChange: (value: string) => void; // Callback function to handle description changes
  defaultValue?: string;
  value?: string;
}
const Textarea: React.FC<TextareaProps> = ({
  placeholder,
  onChange,
  defaultValue,
  value,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    onChange(newValue); // Call the onChange callback with the new value
  };
  return (
    <textarea
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      className="custom-textarea"
      defaultValue={defaultValue}
    />
  );
};

export default Textarea;
