import React from "react";
import "../../styles/Select.css";
import { useTranslation } from "react-i18next";

interface SelectProps {
  options: string[] | undefined;
  placeholder: string;
  defaultSelect: string;
  onChange: (label: string, selectedOption: string) => void;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  placeholder,
  defaultSelect,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOption = event.target.value;
    
    onChange(placeholder, newSelectedOption);
  };

  return (
    <select
      disabled={disabled}
      value={defaultSelect}
      onChange={handleOptionChange}
      className="custom-select"
    >
      <option defaultValue="" hidden>
        {t(placeholder)}
      </option>
      {options?.map((option, index) => (
        <option key={index} value={option} hidden={option === "Unestimated"}>
          {t(option)}
        </option>
      ))}
    </select>
  );
};

export default Select;
