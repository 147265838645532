// useProjectFetch.ts
import React from "react";
import axios from "axios";
import { BASE_URL } from "../config/config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { resetProjectCreated } from "../store/Slices/ProjectSlice";
import getApiKey from "../config/config";

const useProjectFetch = () => {
  const [projectList, setProjectList] = React.useState<[]>([]);
  const dispatch = useDispatch();
  const isCreatedProject = useSelector(
    (state: any) => state.ProjectSlice.newProjectCreated,
  );

  React.useEffect(() => {
    const getApi = getApiKey();
    const apiKey = getApi?.API_KEY;
    const fetchAllProject = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/project/get`, {
          headers: {
            "X-API-KEY": apiKey,
          },
        });
        setProjectList(response.data);
        dispatch(resetProjectCreated());
      } catch (error: any) {
        console.log(`Error While Fetching Project ${error}`);
        toast.error("Cannot Fetch Project");
      }
    };
    fetchAllProject();
  }, [isCreatedProject, dispatch]);

  return projectList;
};

export default useProjectFetch;
