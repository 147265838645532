import socketIOClient, { Socket } from "socket.io-client";
import getApiKey, { SOCKET_URL } from "../config/config";

const endpoint = SOCKET_URL; // TODO add to env: envConfig.SOCKET_URL

let io: Socket;

export const initSocket = (token: string) => {
  const getKey = getApiKey();
  const API_KEY = getKey?.API_KEY
  if (!io) {
    const options = {
      transports: ["polling", "websocket"],
      reconnectionDelayMax: 10000,
      extraHeaders: {
        "x-api-key": token || API_KEY,
      },
    };
    io = socketIOClient(endpoint!, options);
  }

  return io;
};
