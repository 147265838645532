import React from "react";
import { useTranslation } from "react-i18next";
import "../theme.css";
import logo from "../logo.png";
import UserMenu from "./UserMenu/UserMenu";
import DropDown from "./DropDown/DropDown";
import ProjectMenu from "./ProjectMenu/ProjectMenu";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  isLoggedIn: boolean;
  selectedProject?: string;
  currentPage?: "login" | "signup";
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, currentPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userData = sessionStorage.getItem("token");
  const parsedUserData = userData ? JSON.parse(userData) : null;

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="header" data-testid="header">
      <img src={logo} className="logo" alt="logo" />
      {isLoggedIn && <ProjectMenu />}
      <div className="logo-text">{t("logotype")}: </div>
      <div className="accent-text">{t("tagline")}</div>
      {!isLoggedIn && currentPage === "login" && (
        <div className="signup-text">
          <p>
            DON'T HAVE AN ACCOUNT? <span onClick={handleSignUp}>SIGN UP</span>
          </p>
        </div>
      )}
      {!isLoggedIn && currentPage === "signup" && (
        <div className="signup-text">
          <p>
            ALREADY HAVE AN ACCOUNT? <span onClick={handleLogin}>LOGIN</span>
          </p>
        </div>
      )}
      {isLoggedIn && (
        <div className="drop-down">
          <DropDown label={parsedUserData?.data?.login}>
            <UserMenu />
          </DropDown>
        </div>
      )}
    </div>
  );
};

export default Header;
