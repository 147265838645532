import { type ChangeEvent, type FC, useEffect, useRef, useState } from "react";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React from "react";
import "../../styles/Input.css";
import SearchIcon from "@mui/icons-material/Search";

export interface InputProps {
  type?: string;
  isDisabled?: boolean;
  placeholder?: string;
  onValueChange?: (value: string) => void;
  defaultValue?: string;
  defaultFocus?: boolean;
  required?: boolean;
  withSearch?: boolean;
  className?: string;
  autoComplete?: string;
}

const Input: FC<InputProps> = ({
  type,
  isDisabled,
  placeholder,
  onValueChange,
  defaultValue,
  defaultFocus,
  required,
  withSearch = false,
  className,
  autoComplete = "on",
}) => {
  const [value, setValue] = useState<string>("");
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const focusRef = useRef<HTMLInputElement>(null);

  // Apply default focus when the component mount
  useEffect(() => {
    defaultFocus && handleFocus();
  }, [defaultFocus]);

  // Set default value when the component mount
  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const handleFocus = () => {
    focusRef.current?.focus();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsFocus(true);
    onValueChange && onValueChange(e.target.value);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocus(false);
    }, 200);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleCancelIconClick = () => {
    setValue("");
    console.log("canclel click ");
    onValueChange && onValueChange("");
  };

  return (
    <TextField
      inputRef={focusRef}
      fullWidth
      className="CustomInput"
      value={value}
      autoComplete={autoComplete}
      onChange={handleChange}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={handleBlur}
      InputProps={{
        type: showPassword ? "text" : type,
        required,
        className,
        placeholder,
        startAdornment: withSearch ? (
          <InputAdornment position="start">
            <IconButton edge="start" aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
        endAdornment: (
          <InputAdornment position="end">
            {value && isFocus && (
              <CancelRoundedIcon
                color="primary"
                onMouseDown={handleMouseDownPassword}
                onClick={handleCancelIconClick}
                data-testid="cancel-icon"
                className="IconButton"
              />
            )}
            {type === "password" && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <RemoveRedEyeOutlinedIcon
                    color="primary"
                    className="IconButton"
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    color="primary"
                    className="IconButton"
                  />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      disabled={isDisabled}
    />
  );
};

export default Input;
