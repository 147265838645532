import { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from "../config/config";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}/auth/login`,
        { email, password }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return { login, loading };
};
