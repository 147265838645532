import "../theme.css";
import { createTheme } from "@mui/material/styles";
import { getCssVariableValue } from "../utils/getCSSVariableValue"; // Adjust the import path as necessary

// Dynamically fetch CSS variable values with TypeScript, providing default values as a fallback
const primaryColor = getCssVariableValue("--primary-color") || "#37728b"; // Default fallback
const accentColor = getCssVariableValue("--accent-color") || "#DEB657";
const highlightColor = getCssVariableValue("--highlight-color") || "#c77860";
const keyColor = getCssVariableValue("--key-color") || "#58ae81";
const contrastColor = getCssVariableValue("--contrast-color") || "#ffeed8";
const brightColor = getCssVariableValue("--bright-color") || "#f07963";
const borderColor = getCssVariableValue("--border-color") || "#ccc";
const fontFamily = getCssVariableValue("--font-family") || "Arial, sans-serif";
const displayFontFamily =
  getCssVariableValue("--display-font-family") || "Arial, sans-serif";
const accentFontFamily =
  getCssVariableValue("--accent-font-family") || "Arial, sans-serif";
const scriptFontFamily =
  getCssVariableValue("--script-font-family") || "Arial, sans-serif";

const theme = createTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: accentColor },
    error: { main: highlightColor },
    success: { main: keyColor },
    info: { main: brightColor },
    background: { default: contrastColor },
    divider: borderColor,
  },
  typography: {
    fontFamily,
    h1: { fontFamily: displayFontFamily },
    h2: { fontFamily: displayFontFamily },
    h3: { fontFamily: displayFontFamily },
    subtitle1: { fontFamily: accentFontFamily },
    body1: { fontFamily: scriptFontFamily },
    button: { textTransform: "none" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor, // TypeScript now understands this will always be a string
          // Additional styles as needed
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: "13px",
          fontWeight: "normal",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          minWidth: "120px",
        },
      },
    },
    MuiAvatar :{
      styleOverrides :{
        root :{
          height:"24px",
          width:"24px", 
          fontFamily : displayFontFamily, 
          fontSize:"medium"
        }
      }
    }
  },
});

export default theme;
