import { createSlice } from "@reduxjs/toolkit";

interface GetStoryProps {
    getAllStory : any[];
}

const initialState : GetStoryProps = {
    getAllStory : []
}

const GetStorySlice = createSlice({
    name: "GetStory" ,
    initialState,
    reducers : {
        getStory : (state , action) =>{
            state.getAllStory = action.payload
        },
    }
})

export const {getStory} = GetStorySlice.actions
export default GetStorySlice.reducer 