import React from "react";
import { Modal, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import "./ImageModal.css";

interface ImageModalProps {
  open: boolean;
  imageUrl: string;
  imageName: string;
  handleClose: () => void;
  handleDownload: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  open,
  imageUrl,
  imageName,
  handleClose,
  handleDownload,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-box">
        <Box className="modal-header">
          <Button onClick={handleClose} sx={{ color: "black" }}>
            <CloseIcon />
          </Button>
          <Button onClick={handleDownload} sx={{ color: "black" }}>
            <DownloadIcon />
          </Button>
        </Box>
        <Box className="modal-content">
          <img src={imageUrl} alt={imageName} className="modal-img" />
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
