// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend)  // Allows loading translations from a server
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector) // Detects user language
  .init({
    fallbackLng: 'en', // Use English if the detected language is unavailable
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
