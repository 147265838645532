import getApiKey from "../config/config";
import { Config } from "./_config";
import { initializeAxios } from "./_main";
const { user } = Config;

export const fetchAllUsers = (fetchAllUser : boolean) => {
  const path = user.root + user.fetchAll + `${fetchAllUser}`;

  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .get(path)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "User fetched successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};