import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../Select/Select";
import "../../styles/StoryTypeList.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Multiselect from "../Select/MultiSelectOwner";
import { Member } from "../../interface/ProjectInterface";
import { StoryInterface } from "../../interface/StoryInterface";

interface StoryTypeListProps {
    onChange: (label: string, data: string | number) => void;
    selectedStoryType: any;
    isEdit?: boolean;
    patchStoryOwner?: (ownerArray: Member[]) => void;
    selectedStory: StoryInterface;
}

function StoryTypeList({ onChange, selectedStoryType, selectedStory, isEdit, patchStoryOwner }: StoryTypeListProps) {
    const { t } = useTranslation();
    const isFeature =
        selectedStoryType?.storyType === "feature" ||
        selectedStoryType?.storyType === "";

    const projectMember = useSelector((state: RootState) => state.ProjectSlice.projectMember)

    const StoryTypeDataList = [
        { label: "storyType", data: ["feature", "bug", "chore", "release"] },
        {
            label: "points",
            data: isFeature ? ["Unestimated", 1, 2, 3, 4, 5, 6, 7, 8] : [0],
        },
        {
            label: "requester",
            data: projectMember?.map((item) => item.login),
        },
    ];

    const OwnerData: Member[] | undefined = React.useMemo(() => {
        return projectMember?.map((item) => {
            return {
                email: item.email,
                family_name: item.family_name,
                given_name: item.given_name,
                id: item.id,
                login: item.login
            }
        })
    }, [projectMember])


    return (
        <div className="StoryTypeList">
            {StoryTypeDataList.map((item: any , index : number) => {
                return (
                    <div className="StoryTypeList_Menu" key={item.label +index }>
                        <p>{t(`${item.label}`)}</p>
                        <Select
                            disabled={(!isFeature && item?.label === "points") || (item.label === "storyType" && isEdit)}
                            placeholder={item.label}
                            defaultSelect={selectedStoryType[`${item.label}`] !== '' ? selectedStoryType[`${item.label}`] : item.data ? item.data[0] : ""}
                            options={item.data}
                            onChange={onChange}
                        />
                    </div>
                );
            })}

            <div className="StoryTypeList_Menu">
                <p>{t(`owner`)}</p>
                <Multiselect OwnerData={OwnerData !== undefined ? OwnerData : null} patchStoryOwner={patchStoryOwner} selectedStoryOwner={selectedStory?.owners ? selectedStory.owners : []} />
            </div>
        </div>
    );
}

export default StoryTypeList;
