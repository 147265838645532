import "../../styles/LoadingOverlay.css";

const LoadingOverlay = ({ isLoading }: any) => {
  if (!isLoading) return <></>;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingOverlay;
