import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import "../../styles/Project.css";
import DialogBox from "../../components/DialogBox/DialogBox";
import AddMember from "./AddMember";
import "../../styles/AddMember.css";
import Input from "../../components/Input/Input";
import MemberList from "./MemberList";
import NavBar from "../../components/NavBar/NavBar";

function Member() {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const [userSearch, setUserSearch] = React.useState<string>("");

  return (
    <div className="ProjectMember_parent">
      <div className="NavDiv">
        <NavBar />
      </div>
      <div className="ProjectPage" data-testid="Project-ID">
        <h1>{t(" Member")}</h1>
        <div className="Member_Header">
          <Input
            placeholder="Find by name or email"
            withSearch={false}
            className="Member_Input"
            onValueChange={(value) => setUserSearch(value)}
          />

          <Button
            label={t("InvitePeople")}
            isPrimary={true}
            onClick={() => setDialogOpen(true)}
          />
        </div>
        <div className="Member_List">
          <MemberList userSearch={userSearch.toLowerCase()} />
        </div>
        {dialogOpen && (
          <DialogBox
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            title={t("InvitePeople")}
          >
            <AddMember setDialogOpen={setDialogOpen} />
          </DialogBox>
        )}
      </div>
    </div>
  );
}

export default Member;
