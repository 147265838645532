import getApiKey from "../config/config";
import { Config } from "./_config";
import { initializeAxios } from "./_main";
const { storyInContext } = Config;

export const storyDragAndDrop = (dragId: string, data: any) => {
  const path = storyInContext.root + storyInContext.drag + `/${dragId}`;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .patch(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res?.data || {},
            message: res.data.message || "Story Status updated successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};
