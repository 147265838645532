import React, { useState, FormEvent } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Input from "../../components/Input/Input";
import Button, { ButtonProps } from "../../components/Button/Button";
import "../../styles/Signup.css";
import { isValidEmail } from "../../utils/HelperFunction";
import { useRegister } from "../../hooks/useRegister";
import Header from "../../components/Header";

interface InputLabelFunctionProps {
  type: string;
  label?: string;
  handleInputData: (value: string) => void;
}

const InputLabelFunction: React.FC<InputLabelFunctionProps> = ({
  type,
  label,
  handleInputData,
}) => (
  <Box className="inputContainer">
    <Typography
      variant="subtitle1"
      fontFamily={"var(--display-font-family)"}
      className="inputLabel"
    >
      {label}
    </Typography>
    <Box className="Input_Fields">
      <Input type={type} onValueChange={handleInputData} />
    </Box>
  </Box>
);

const SignUp: React.FC = () => {
   const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    loginName: "",
    givenName: "",
    familyName: "",
  });
  const navigate = useNavigate();
  const { register } = useRegister();
  const { t } = useTranslation();

  const handleChange = (field: string) => (value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleValidation = () => {
    const { email, password, loginName, givenName, familyName } = formData;
    if (!email || !password || !loginName || !givenName || !familyName) {
      toast.error("Fill in all the details");
      return false;
    }
    if (!isValidEmail(email)) {
      toast.error("Email is not valid!!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!handleValidation()) return;

    try {
      await register(
        formData.email,
        formData.password,
        formData.loginName,
        formData.givenName,
        formData.familyName,
      );
      setIsLoggedIn(true);
      setFormData({
        email: "",
        password: "",
        loginName: "",
        givenName: "",
        familyName: "",
      });
      navigate("/login");
    } catch (err: any) {
      console.error(`Error in login: ${err}`);
      toast.error(err.response?.data?.message || "Registration failed");
    }
  };

  const buttonProps: ButtonProps = {
    type: "submit",
    variant: "contained",
    isPrimary: true,
    label: t("Sign Up"),
    onClick: handleSubmit,
  };

  return (
    <div className="Signup_Container" data-testid="signupId">
      <div className="header_div">
        <Header isLoggedIn={isLoggedIn} currentPage="signup"/>
      </div>
      <form className="Signup_Container" onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {t("Sign Up Here")}
        </Typography>
        <Box className="SignupBox">
          <Box className="inputRow">
            <InputLabelFunction
              type="text"
              label={t("firstname")}
              handleInputData={handleChange("givenName")}
            />
            <InputLabelFunction
              type="text"
              label={t("lastname")}
              handleInputData={handleChange("familyName")}
            />
            <InputLabelFunction
              type="email"
              label={t("email")}
              handleInputData={handleChange("email")}
            />
            <InputLabelFunction
              type="password"
              label={t("password")}
              handleInputData={handleChange("password")}
            />
            <InputLabelFunction
              type="text"
              label={t("username")}
              handleInputData={handleChange("loginName")}
            />
          </Box>
          <Box className="buttonContainer">
            <Button {...buttonProps} />
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default SignUp;
