import React from 'react';
import { WorkflowTransition } from '../interface/WorkflowInterface';
import { StoryInterface } from '../interface/StoryInterface'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { storyCreated } from '../store/Slices/UpdateStorySlice';
import { startUpdateStatusStory } from '../api/story';

interface WorkflowWidgetProps {
  story: StoryInterface;
  workflowData: any;
}

const WorkflowWidget: React.FC<WorkflowWidgetProps> = ({ story, workflowData }) => {
  const [currentState, setCurrentState] = React.useState<WorkflowTransition[]>();
  const dispatch = useDispatch();
  const getCurrentState = () => {
    const currentWorkflow = workflowData?.filter((item: any) => item.id === story?.workflow_id)
    return currentWorkflow;
  };

  const getAvailableTransitions = (): WorkflowTransition[] => {
    const currentState = getCurrentState();
    if (!currentState) return [];
    return currentState[0].transitions;
  };

  const availableTransitions = getAvailableTransitions();

  const handleTransition = async (Event: React.MouseEvent<HTMLButtonElement>, dislpayStateName: WorkflowTransition) => {
    if (story?.estimate === null && story?.type === "feature") {
      toast.info("Select estimation point");
      return;
    }
    Event.stopPropagation();
    const findStatusById = availableTransitions?.filter((item) => item.source_status_id === dislpayStateName.target_status_id)
    try {
      const response = await startUpdateStatusStory(story?.id, { status_id: dislpayStateName.target_status_id })
      if (response.success) {
        toast.success("Status Updated successfully")
        setCurrentState(findStatusById);
        dispatch(storyCreated());
      }
    }
    catch (error) {
      console.log(`Error updating status ${error}`)
      toast.error('Cannot Update Status')
    }
  }

  React.useEffect(() => {
    const findStatusById = availableTransitions?.filter((item: any) => item.source_status_id === story?.status_id)
    setCurrentState(findStatusById)
  }, [story, availableTransitions])

  const handleDivClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // Prevent event propagation to ancestor elements
  };

  return (
    <div className="workflow_controls" onClick={handleDivClick}>
      {
        currentState?.map((item) => {
          return <button type='button' className={`workflow_button ${item.name}`} onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleTransition(e, item)} key={item.id}>
            {item.name}
          </button>
        })
      }
    </div>
  );
};

export default WorkflowWidget;
