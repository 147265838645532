export const BASE_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export default function getApiKey() {
  const token = sessionStorage.getItem("token");
  if (token) {
    const parse = JSON.parse(token);
    return parse;
  }
  return undefined;
}

export const getUser = () => {
  const user = sessionStorage.getItem("token");
  if (user) {
    return JSON.parse(user)?.data;
  }

  return undefined;
};
