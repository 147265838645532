import React from 'react';
import { getInitials } from '../../utils/HelperFunction';
import { Box } from '@mui/material';
import Input from '../Input/Input';
import "../../styles/Comment.css";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Tooltip from "@mui/material/Tooltip";
import EmojiPicker from "emoji-picker-react";
import Button from '../Button/Button';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Attachments, CommentData } from '../../interface/CommentInterface';
import CloseIcon from "@mui/icons-material/Close";
import { FileItems } from './Comment';
import { uploadFile } from '../../api/storage';
import { toast } from 'react-toastify';
import { updateComment } from '../../api/comment';

interface EditCommentProps {
    editComment : CommentData ; 
    editCommentData : any ;
    handleEditFunction : (key : string , value : any) => void ;  
    editInputValue : string ;
    setEditInputValue : React.Dispatch<React.SetStateAction<string>> 
    handleCloseEdit : () => void ; 
    count : number ; 
    setCount  : React.Dispatch<React.SetStateAction<number>>
}

function EditComment({
    editComment , 
    editCommentData , 
    handleEditFunction , 
    editInputValue , 
    setEditInputValue,
    handleCloseEdit,
    count , 
    setCount 
} : EditCommentProps) {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [editAttachment , setEditAttachment ] = React.useState<Attachments[]>(editComment.attachments) 
    const [addNewFile , setAddNewFile] = React.useState<File[]>([]) 
    const [fileData, setFileData] = React.useState<FileItems[]>([])

    const handleAttachmentClick = () => {
        fileInputRef.current?.click();
    };

    const handleChange = (key : string , value: any) =>{
        handleEditFunction(key , value) ; 
    }

    const handleEmojiClick =(emojiObject: { emoji: string }) =>{
        const addEmoji = editInputValue + emojiObject.emoji 
        setEditInputValue(addEmoji) ;
        handleChange("showEmojiPicker" , false) 
    }

    const updateAttachment = (index : number) =>{
        const originalFileData = [...editAttachment];
        originalFileData.splice(index, 1);
        setEditAttachment(originalFileData);
    }
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        const file = event.target.files?.[0];
        if (file) {
          const generatefileUrl = URL.createObjectURL(file);
          setAddNewFile([...addNewFile!, file]);
          setFileData([...fileData, { fileUrl: generatefileUrl, fileName: file.name, fileType: file.type }])
        }
    }

    const handleRemoveAttachment = (index: number) => {
        const originalFileData = [...fileData];
        originalFileData.splice(index, 1);
        setFileData(originalFileData);
    
        const originalFiles = [...addNewFile];
        originalFiles.splice(index, 1);
        setAddNewFile(originalFiles);
      };

      const handleUpdateComment = async() =>{
        try {
            let modifiedData;
            if (addNewFile) {
              const response = await uploadFile({ files : addNewFile })
              modifiedData = response.body.data.map((item: any) => {
                const { encoding, ...rest } = item;
                return rest; // Return the modified object without the 'encoding' field
              });
            }
            let NewArray = [...modifiedData , ...editAttachment]
            await updateComment( editComment.id, { comment_text: editInputValue, attachments: NewArray })
            handleCloseEdit();
            setCount(count + 1 ) ;  
            toast.success("Post successfull")
          }
          catch (error) {
            toast.error("Error Posting story")
            console.error(`Error posting comment ${error}`)
          }
      }

    return <>
        <div className="Comment_header" >
            <p>Edit Comment </p>
        </div>
        <div className="Comment_Content" style={{ position: "relative" }}>
            <div className="Member_initials">
                {getInitials(editComment.user.given_name)}
            </div>
            <Box width="100%">
                <Input
                    placeholder="comment here!"
                    onValueChange={(value: string) => setEditInputValue(value)}
                    defaultValue={editInputValue}
                />
            </Box>
            <Button
                label={<EmojiEmotionsIcon />}
                onClick={() => handleChange("showEmojiPicker" , !editCommentData.showEmojiPicker)}
            />
            {editCommentData.showEmojiPicker && (
                <div className="Emoji_Picker">
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
            )}
        </div>
        <div className="Comment_Post">
            <div className="Comment_Attachment">
                <Tooltip title="Add attachment to comment" placement="right">
                    <p onClick={handleAttachmentClick}>
                        <AttachmentIcon />
                    </p>
                </Tooltip>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
                {
                    fileData?.map((item: FileItems, index: number) => {
                        return item.fileType?.startsWith("image/") ?
                            <div className="Comment_Attachment_Img" key={item.fileUrl}>
                                <img src={item.fileUrl} alt={item.fileName ?? "Image"} />
                                <CloseIcon
                                    onClick={() => handleRemoveAttachment(index)}
                                    className="close"
                                    fontSize="small"
                                />
                            </div>
                            : <div className="Comment_Attachment_Docs" key={item.fileUrl}>
                                <InsertDriveFileIcon />
                                <span>{item.fileName}</span>
                                <CloseIcon onClick={() => handleRemoveAttachment(index)} className="close" />
                            </div>
                    })
                }
                {editAttachment &&
                        editAttachment?.map((file: any , index : number) => {
                          return (
                            <div key={file.name}>
                              {file.mimetype?.startsWith("image/") ? (
                                <div
                                  className="Comment_Attachment_Img"
                                  key={file.url}
                                >
                                  <img
                                    src={file.url}
                                    alt={file.url ?? "Image"}
                                  />
                                  <CloseIcon
                                    onClick={() => updateAttachment(index)}
                                    className="close"
                                    fontSize="small"
                                  />
                                </div>
                              ) : (
                                <div className="Attached_Docs_Edit">
                                  <InsertDriveFileIcon />
                                  <p>{file.original_name}</p>
                                  <CloseIcon
                                    onClick={() => updateAttachment(index)}
                                    className="close"
                                    fontSize="small"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })
                      }
            </div>
            <div className='Edit_Buttons'>
                <Button label={"Cancel"} onClick={handleCloseEdit} />
                <Button label={"Update"} onClick={handleUpdateComment} />
            </div>
        </div>
    </>
}

export default EditComment; 