import React from "react";
import { Box, Typography } from "@mui/material";
import Input from "../../components/Input/Input";
import Button, { ButtonProps } from "../../components/Button/Button";
import "../../styles/LoginPage.css";
import "../../../src/theme.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { isValidEmail } from "../../utils/HelperFunction";
import { useLogin } from "../../hooks/useLogin";
import { useTranslation } from "react-i18next";

interface InputLabelFunctionProps {
  type: string;
  label?: string;
  placeholder?: string;
  handleInputData: (value: string) => void;
}

export const InputLabelFunction: React.FC<InputLabelFunctionProps> = (
  props,
) => {
  return (
    <Box className="inputContainer">
      <Typography
        variant="subtitle1"
        fontFamily={"var(--display-font-family)"}
        className="inputLabel"
      >
        {props.label}
      </Typography>
      <Box className="Input_Fields">
        <Input type={props.type} onValueChange={props.handleInputData} />
      </Box>
    </Box>
  );
};

const LoginPage: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const navigate = useNavigate();
  const { login } = useLogin();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    Promise.resolve(login(email, password))
      .then((response) => {
        if (
          response === null ||
          response === undefined ||
          response?.API_KEY === null ||
          response?.API_KEY === undefined
        ) {
          toast.error("Internal Server Error");
          throw new Error("Response is null or undefined");
        }
        setEmail("");
        setPassword("");
        setIsLoggedIn(true);
        navigate("/project-list");
        sessionStorage.setItem("token", JSON.stringify(response));
      })
      .catch((err: any) => {
        console.log(`Error in login ${err}`);
        toast.error(`${err.response?.data?.message}`);
      });
  };

  const { t } = useTranslation();

  const buttonProps: ButtonProps = {
    type: "submit",
    variant: "contained",
    isPrimary: true,
    label: t("Submit"),
    onClick: handleSubmit,
  };

  const handleInputChange = (value: string) => {
    setEmail(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleValidation = () => {
    if (email === "" || password === "") {
      toast.error("Fill in all the details");
      return false;
    } else if (!isValidEmail(email)) {
      toast.error("Email is not valid!!");
      return false;
    }
    return true;
  };

  return (
    <div className="Login_Container">
      <form className="Login_Container" onSubmit={handleSubmit}>
        <div className="header_div">
          <Header isLoggedIn={isLoggedIn} currentPage="login" />
        </div>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <Box className="loginBox">
          <Box className="inputRow">
            <InputLabelFunction
              type="text"
              label={t("email")}
              handleInputData={handleInputChange}
            />
            <InputLabelFunction
              type="password"
              label={t("password")}
              handleInputData={handlePasswordChange}
            />
          </Box>
          <Box className="buttonContainer">
            <Button {...buttonProps} />
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default LoginPage;
