import DropDown from "../DropDown/DropDown";
import "../../styles/Comment.css";
import React, { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import {
  deleteComment,
  getComment,
  startCreateComment,
} from "../../api/comment";
import { StoryInterface } from "../../interface/StoryInterface";
import { toast } from "react-toastify";
import { CommentData } from "../../interface/CommentInterface";
import { capitalizedName } from "../../utils/CommonFunction";
import { uploadFile } from "../../api/storage";
import ImageModal from "../ImageModal/ImageModal";
import {
  downloadFile,
  downloadImage,
  getInitials,
} from "../../utils/HelperFunction";
import PostComment from "./PostComment";
import EditComment from "./EditComment";

interface CommentProps {
  selectedStory: StoryInterface;
}

export interface FileItems {
  fileUrl: string;
  fileName: string;
  fileType: string;
}

function Comment({ selectedStory }: CommentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [getCommentList, setGetCommentList] = useState<CommentData[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileData, setFileData] = React.useState<FileItems[]>([]);
  const [count, setCount] = React.useState<number>(0);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalImageUrl, setModalImageUrl] = React.useState("");
  const [modalImageName, setModalImageName] = React.useState("");
  const [editComment, setEditComment] = React.useState<CommentData | undefined>(
    undefined,
  );
  const [editCommentData, setEditCommentData] = React.useState({
    showEmojiPicker: false,
  });
  const [editInputValue, setEditInputValue] = React.useState<string>("");

  const handlePostComment = async () => {
    try {
      let modifiedData;
      if (files) {
        const response = await uploadFile({ files });
        modifiedData = response.body.data.map((item: any) => {
          const { encoding, ...rest } = item;
          return rest; // Return the modified object without the 'encoding' field
        });
      }
      await startCreateComment({
        story_id: selectedStory.id,
        comment_text: comment,
        attachments: modifiedData,
      });
      setComment("");
      setFiles([]);
      setCount(count + 1);
      setFileData([]);
      toast.success("Post successfull");
    } catch (error) {
      toast.error("Error Posting story");
      console.error(`Error posting comment ${error}`);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const generatefileUrl = URL.createObjectURL(file);
      setFiles([...files!, file]);
      setFileData([
        ...fileData,
        { fileUrl: generatefileUrl, fileName: file.name, fileType: file.type },
      ]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    const originalFileData = [...fileData];
    originalFileData.splice(index, 1);
    setFileData(originalFileData);

    const originalFiles = [...files];
    originalFiles.splice(index, 1);
    setFiles(originalFiles);
  };

  const handleEmojiClick = (emojiObject: { emoji: string }) => {
    setComment(comment + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  React.useEffect(() => {
    const getCommentAPI = async () => {
      try {
        const response = await getComment(selectedStory!.id);
        setGetCommentList(response.body.rows);
      } catch (error) {
        toast.error("Error fetching comment");
      }
    };
    getCommentAPI();
  }, [count, selectedStory]);

  const handleImageClick = (url: string, name: string) => {
    setModalImageUrl(url);
    setModalImageName(name);
    setModalOpen(true);
  };

  const handleChangeEditComment = (key: string, value: any) => {
    setEditCommentData({ ...editCommentData, [key]: value });
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteComment(commentId);
      setGetCommentList(
        getCommentList.filter((comment) => comment.id !== commentId),
      );
      toast.success("Comment deleted successfully");
    } catch (error) {
      console.error(`Error deleting comment ${error}`);
      toast.error("Error deleting comment");
    }
  };

  return (
    <div data-testid="commentID">
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {getCommentList.map((value, index) => {
          const date = new Date(value.updated_at);
          return (
            <div key={value.id}>
              {editComment && editComment.id === value.id ? (
                <EditComment
                  editComment={editComment}
                  editCommentData={editCommentData}
                  editInputValue={editInputValue}
                  setEditInputValue={setEditInputValue}
                  handleEditFunction={(key: string, value: any) =>
                    handleChangeEditComment(key, value)
                  }
                  handleCloseEdit={() => setEditComment(undefined)}
                  count={count}
                  setCount={setCount}
                />
              ) : (
                <>
                  <div className="Comment_Info">
                    <div className="Comment_Img">
                      {getInitials(value.user.given_name)}
                    </div>
                    <div>
                      <p>
                        {capitalizedName(value.user.given_name) +
                          " " +
                          capitalizedName(value.user.family_name)}
                      </p>
                    </div>
                    <div className="Comment_Edit">
                      <DropDown label={""} arrowColor="black">
                        <div className="Comment_options">
                          <p
                            onClick={() => {
                              setEditComment(value);
                              setEditInputValue(value.comment_text);
                            }}
                          >
                            Edit
                          </p>
                          <p onClick={() => handleDeleteComment(value.id)}>
                            Delete
                          </p>
                        </div>
                      </DropDown>
                    </div>
                  </div>
                  <div className="Comment">
                    <p>{value.comment_text}</p>
                    {value.attachments &&
                      value.attachments?.map((file: any) => {
                        console.log("file", file);
                        return (
                          <div key={file.name}>
                            {file.mimetype?.startsWith("image/") ? (
                              <div className="Attached_Img">
                                <img
                                  src={file.url}
                                  alt={file.original_name ?? "Image"}
                                  height={200}
                                  width={200}
                                  onClick={() =>
                                    handleImageClick(
                                      file.url,
                                      file.original_name,
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div className="Attached_Docs">
                                <InsertDriveFileIcon />
                                <p>{file.original_name}</p>
                                <Tooltip title="Download" placement="right">
                                  <button
                                    onClick={(
                                      e: React.MouseEvent<HTMLButtonElement>,
                                    ) => {
                                      e.preventDefault();
                                      downloadFile(
                                        file.url,
                                        file.original_name,
                                        file.type,
                                      );
                                    }}
                                  >
                                    <DownloadIcon className="download" />
                                  </button>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div
                    style={{
                      fontSize: "small",
                      color: "#676E7A",
                      display: "flex",
                      marginLeft: "50px",
                    }}
                  >
                    <p>
                      {date.toLocaleDateString(undefined, {
                        month: "short",
                        day: "numeric",
                      }) +
                        ", " +
                        date.toLocaleTimeString(undefined, {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                    </p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <PostComment
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setComment={setComment}
        comment={comment}
        setShowEmojiPicker={setShowEmojiPicker}
        showEmojiPicker={showEmojiPicker}
        handleEmojiClick={handleEmojiClick}
        fileData={fileData}
        isEdit={false}
        handleFileChange={(e: any) => handleFileChange(e)}
        handleRemoveAttachment={(value: number) =>
          handleRemoveAttachment(value)
        }
        handlePostComment={handlePostComment}
      />
      <ImageModal
        open={modalOpen}
        imageUrl={modalImageUrl}
        imageName={modalImageName}
        handleClose={() => setModalOpen(false)}
        handleDownload={() => downloadImage(modalImageUrl, modalImageName)}
      />
    </div>
  );
}

export default Comment;
