import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight
} from "react-icons/fa";
import "../../styles/Sidebar.css";
import { useTranslation } from "react-i18next";


interface SidebarProps {
  image?: string;
  collapsed?: boolean;
  handleCollapsedChange?: () => void;
  setDisplayContext: React.Dispatch<React.SetStateAction<any[]>>;
  displayContext: any[];
  fetchContext: any;
  setFetchContext: React.Dispatch<React.SetStateAction<any>>;
}

const SideBar: React.FC<SidebarProps> = ({
  collapsed,
  handleCollapsedChange,
  setDisplayContext,
  displayContext,
  fetchContext,
  setFetchContext
}) => {
  const { t } = useTranslation();

  const handleMenuItemClick = (index: number, item: any) => {
    const updatedMenuItems = [...fetchContext];
    updatedMenuItems[index].selected = !updatedMenuItems[index].selected;
    setFetchContext(updatedMenuItems);
    if (displayContext.includes(item)) {
      setDisplayContext(displayContext.filter((it) => it !== item));
    } else {
      setDisplayContext([...displayContext, item]);
    }
  };

  const fetchIcon = (icon: string) => {
    if (!icon) return null;
    // load the image at /assets/contexts/<icon>.png
    return (
      <img
        src={require(`../../../assets/contexts/${icon}.png`)}
        alt={icon}
        width="30"
        height="30"
      />
    );
  };

  return (
    <div data-testid="sidebar">
      <Sidebar collapsed={collapsed} className="Sidebar_Main">
        <Menu className="Sidebar_MenuItem ">
          <MenuItem
            icon={collapsed ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
            onClick={handleCollapsedChange}
            className="Sidebar_MenuItem_Header"
          />
        </Menu>
        <div>
          <Menu>
            {fetchContext.map((item: any, index: number) => (
              <MenuItem
                icon={fetchIcon(item.icon)}
                className={`Sidebar_MenuItem ${item.selected ? "selected" : ""}`}
                key={index}
                onClick={() => handleMenuItemClick(index, item)}
              >
                {t(item.name)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
