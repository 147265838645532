import { configureStore } from '@reduxjs/toolkit'
import products from './Slices/slice'
import auth from './Slices/AuthSlice'
import ProjectSlice from './Slices/ProjectSlice'
import CreateStorySlice from './Slices/UpdateStorySlice'
import GetStorySlice from './Slices/GetStorySlice'
import FetchStorySlice from './Slices/FetchStorySlice'
import AllContextStory from './Slices/AllContextStoryData'
import MemberSlice from './Slices/MemberSlice'
import MultiUserSlice from './Slices/MultiUserUpdate'

const store = configureStore({
  reducer: {
    products,
    auth,
    ProjectSlice, 
    CreateStorySlice, 
    GetStorySlice, 
    FetchStorySlice, 
    AllContextStory, 
    MemberSlice,
    MultiUserSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
