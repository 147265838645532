import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/Tracker.css";

function NavBar() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <nav className="Tracker_Nav">
      <ul className="Tracker_Nav_List">
        <li onClick={() => navigate(`/project/${id}`)}>{t("stories")}</li>
        <li onClick={() => navigate(`/project/${id}/addMember`)}>
          {t("Member")}
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
