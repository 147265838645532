import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import { routers } from "./Router";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <div>
    <Provider store={store}>
      <RouterProvider router={routers} />
    </Provider>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      pauseOnHover={true}
      hideProgressBar={false}
      draggable={true}
      closeOnClick={true}
    />
  </div>,
);
