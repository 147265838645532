import { BASE_URL } from "../config/config";

export interface MethodsEntity {
  GET: "get";
  POST: "post";
  PUT: "put";
  PATCH: "patch";
  DELETE: "delete";
}

export const Config = {
  baseURL: BASE_URL,
  auth: {
    root: "/auth",
    login: "/login",
    register: "/signup",
    logout: "/logout/:id",
  },
  user: {
    root: "/user",
    fetchAll: "?fetchAll=",
  },
  story: {
    root: "/story",
    updateStatus: "/updateStatus",
    update: "/:id",
  },
  comment: {
    root: "/comment",
    story_id: "?story_id=",
  },
  context: {},
  stories: {},
  status: {},
  storage: {
    root: "/storage",
    uploads: "/upload",
  },
  workflow: {
    root: "/workflow",
    get: "/get",
  },

  storyInContext: {
    root: "/story-context",
    drag: "/drag",
  },

  project: {
    root: "/project",
    get: "/get",
    context: "/context",
    story: "/story",
    addMember: "/addMember",
    removeMember: "/removeMember",
    createWithImport: "/createWithImport",
  },

  methods: {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
  } as MethodsEntity,
};
