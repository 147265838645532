import React from "react";
import { getInitials } from "../../utils/HelperFunction";
import { Box } from "@mui/material";
import Input from "../Input/Input";
import "../../styles/Comment.css";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import Button from "../Button/Button";
import { FileItems } from "./Comment";

interface PostCommentProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  setShowEmojiPicker: React.Dispatch<React.SetStateAction<boolean>>;
  showEmojiPicker: boolean;
  handleEmojiClick: (emojiObject: EmojiClickData) => void;
  // handleAttachmentClick: () => void;
  fileData: any[];
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveAttachment: (index: number) => void;
  handlePostComment: () => void;
  isEdit: boolean;
  handleCloseEdit?: () => void;
  handleUpdateComment?: () => void;
}

function PostComment({
  setIsOpen,
  handleUpdateComment,
  handleCloseEdit,
  isOpen,
  isEdit,
  setComment,
  comment,
  setShowEmojiPicker,
  showEmojiPicker,
  handleEmojiClick,
  fileData,
  handleFileChange,
  handlePostComment,
  handleRemoveAttachment,
}: PostCommentProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const userData = sessionStorage.getItem("token");
  const parsedUserData = userData ? JSON.parse(userData) : null;
  return (
    <>
      <div className="Comment_header" onClick={() => setIsOpen(!isOpen)}>
        <p>Comment Section</p>
        {/* <DropDown label={selectedSort} isOpen={isOpen}>
                <SortItem handleSort={() => true} />
            </DropDown> */}
      </div>
      <div className="Comment_Content" style={{ position: "relative" }}>
        <div className="Member_initials">
          {getInitials(parsedUserData?.data?.login)}
        </div>
        <Box width="100%">
          <Input
            placeholder="comment here!"
            onValueChange={(value: string) => setComment(value)}
            defaultValue={comment}
          />
        </Box>
        <Button
          label={<EmojiEmotionsIcon />}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
        {showEmojiPicker && (
          <div className="Emoji_Picker">
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
      </div>
      <div className="Comment_Post">
        <div className="Comment_Attachment">
          <Tooltip title="Add attachment to comment" placement="right">
            <p onClick={handleAttachmentClick}>
              <AttachmentIcon />
            </p>
          </Tooltip>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {fileData?.map((item: FileItems, index: number) => {
            return item.fileType?.startsWith("image/") ? (
              <div className="Comment_Attachment_Img" key={item.fileUrl}>
                <img src={item.fileUrl} alt={item.fileName ?? "Image"} />
                <CloseIcon
                  onClick={() => handleRemoveAttachment(index)}
                  className="close"
                  fontSize="small"
                />
              </div>
            ) : (
              <div className="Comment_Attachment_Docs" key={item.fileUrl}>
                <InsertDriveFileIcon />
                <span>{item.fileName}</span>
                <CloseIcon
                  onClick={() => handleRemoveAttachment(index)}
                  className="close"
                />
              </div>
            );
          })}
        </div>
        {!isEdit && (
          <Button label={"Post Comment"} onClick={handlePostComment} />
        )}
        {isEdit && (
          <>
            <Button label={"Cancel"} onClick={handleCloseEdit} />
            <Button label={"Update"} onClick={handleUpdateComment} />
          </>
        )}
      </div>
    </>
  );
}

export default PostComment;
