import React from 'react'
import '../../styles/MultiSelectOwner.css'
import { Avatar, AvatarGroup } from '@mui/material';
import { FaChevronDown } from 'react-icons/fa';
import { Member } from '../../interface/ProjectInterface';

interface OwnerSelectProps {
    OwnerData: Member[] | null | undefined
    patchStoryOwner?: (ownerArray: Member[]) => void;
    selectedStoryOwner: Member[];
}

function OwnerSelect({ OwnerData, patchStoryOwner, selectedStoryOwner }: OwnerSelectProps) {

    const [openPaper, setOpenPaper] = React.useState<boolean>(false);
    const [selectedOwners, setSelectedOwner] = React.useState<any[]>(selectedStoryOwner ? selectedStoryOwner : [])
    const ownerSelectRef = React.useRef<HTMLDivElement>(null);


    const handleSelectOwner = (e: React.MouseEvent<HTMLLIElement>, item: Member) => {
        setOpenPaper(false)
        e.stopPropagation();
        if (selectedOwners.find(obj => obj.id === item.id)) {
            const removeOwner = selectedOwners.filter((val) => val.id !== item.id)
            setSelectedOwner(removeOwner);
            patchStoryOwner && patchStoryOwner(removeOwner)
        }
        else {
            const addStoryOwner = [...selectedOwners, item]
            setSelectedOwner(addStoryOwner);
            patchStoryOwner && patchStoryOwner(addStoryOwner)
        }
    }


    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ownerSelectRef.current && !ownerSelectRef.current.contains(event.target as Node)) {
                setOpenPaper(false);
            }
        };
        if (openPaper) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPaper]);

    return <div className='multiselect' style={{ justifyContent: selectedOwners.length ? "flex-end" : "space-between" }} onClick={() => setOpenPaper(true)} ref={ownerSelectRef}>
        <AvatarGroup max={3} >
            {
                selectedOwners.length ? selectedOwners.map((item, index) =>
                    <Avatar key={index + 12} alt={item.login} sx={{ width: 24, height: 24 }}>{item?.given_name[0]}</Avatar>
                ) : (
                    <p>none</p>
                )
            }
        </AvatarGroup>


        <FaChevronDown size={11} />
        {
            openPaper && <div className='MultiSelect_Paper'>
                <ul className='MultiSelect_List'>
                    {OwnerData?.map((item) => {
                        return <li key={item.login} onClick={(e: React.MouseEvent<HTMLLIElement>) => handleSelectOwner(e, item)} >{item.given_name}</li>
                    })}
                </ul>
            </div>
        }
    </div>
}
export default OwnerSelect; 
