import getApiKey from "../config/config";
import { Config } from "./_config";
import { initializeAxios } from "./_main";
const { story } = Config;

export const startCreateStory = (data: any) => {
  const path = story.root;

  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .post(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "story fetched successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const startDeleteStory = (id: any) => {
  const path = story.root + `/${id}`;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .delete(path)
        .then((res) => {
          resolve({
            success: true,
            body: res?.data || {},
            message: res.data?.message || "story deleted successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const startUpdateStory = (id: string, data: any) => {
  const path = story.root + `/${id}`;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .patch(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res?.data || {},
            message: res.data.message || "Story updated successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const startUpdateStatusStory = (id: string, data: any) => {
  const path = story.root + `/${id}` + story.updateStatus;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .patch(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res?.data || {},
            message: res.data.message || "Story Status updated successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const importStories = (data: any) => {
  const path = story.root + `/import`;
  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .post(path, data, {
          headers:{
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          resolve({
            success: true,
            body: res?.data || {},
            message: res.data.message || "successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};
