import { createBrowserRouter } from "react-router-dom";
import Error from "./Error";
import App from "./App";
import React from "react";
import LoginPage from "./pages/LoginPage/LoginPage";
import { AuthProvider } from "./utils/AuthProvider";
import Project from "./pages/ProjectPage/Project";
import Tracker from "./pages/Tracker/Tracker";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/theme";
import Member from "./pages/Members/Member";
import SignUp from "./pages/SignupPage/SignUp";

export const routers = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "/project-list",
        element: <Project />,
      },
      {
        path: "/project/:id",
        element: <Tracker />,
      },
      {
        path: "/project/:id/addMember",
        element: <Member />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ThemeProvider theme={theme}>
        <LoginPage />
      </ThemeProvider>
    ),
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
]);
