/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Story from "./Story";
import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Workflow } from "../interface/WorkflowInterface";
import { startGetWorkflows } from "../api/workflow";
import { StoryInterface, StoryLink } from "../interface/StoryInterface";
import StoryComp from "./StoryComp/StoryComp";
import { startDeleteStory } from "../api/story";
import { useDispatch } from "react-redux";
import { storyDeleted } from "../store/Slices/UpdateStorySlice";
import useSocket from "../hooks/useSocket";

interface StoryListProps {
  contextName?: string;
  getContextStory?: string;
  setUpdateStoryAfterCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  updateStoryAfterCreate?: boolean;
  stories: StoryLink[];
  storyModifying?: {
    storyId: string;
    userId: string;
  };
}

const StoryList: React.FC<StoryListProps> = ({
  storyModifying,
  contextName,
  getContextStory,
  stories,
}) => {
  // const extractedStories: StoryInterface[] = stories.map((item) => item.story);

  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [workflowData, setWorkflowData] = React.useState<Workflow[] | null>([]);
  const [isEditStory, setIsEditStory] = React.useState<boolean>(false);
  const [selectedStory, setSelectedStory] =
    React.useState<StoryInterface | null>(null);

  const { emit } = useSocket({ topic: "messages" });

  React.useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await startGetWorkflows();
        setWorkflowData(response?.body);
      } catch (error) {
        console.log(`Error fetching workflow ${error}`);
        toast.error("Cannot fetch workflows");
      }
    };
    fetchWorkflows();

    return () => {
      setIsEditStory(false);
      setSelectedStory(null);
    };
  }, []);

  const dispatch = useDispatch();

  const handleEditStory = (data: StoryInterface) => {
    //EMIT
    emit("open_story_modifying", data?.id);

    setSelectedStory(data);
    setIsEditStory(!isEditStory);
  };

  const handleClose = (storyId: string) => {
    emit("close_story_modifying", storyId);
    setIsEditStory(false);
  };

  const handleDeleteStory = async (storyId: string) => {
    try {
      const confirmDeleteStory = window.confirm(
        "Do you want to delete this story?",
      );
      if (confirmDeleteStory) {
        await startDeleteStory(storyId);
        dispatch(storyDeleted());
        setDeleteOpen(false);
        toast.success("Story deleted successfully");
      }
    } catch (error) {
      toast.error("Error deleting story");
    }
  };

  return (
    <div className="story-list" data-testid="story-list">
      {stories.map((item: any, index: number) => {
        if (selectedStory?.id === item?.id && isEditStory) {
          return (
            <div key={item.id + index}>
              {isEditStory && (
                <StoryComp
                  storyModifying={storyModifying}
                  storyId={item?.id}
                  isEdit={isEditStory}
                  selectedStory={item?.story}
                  handleClose={() => handleClose(item.id)}
                  getContextStory={""}
                  stories={stories}
                  onDeleteStory={() => handleDeleteStory(item.id)}
                  workflowData={workflowData}
                />
              )}
            </div>
          );
        } else {
          return (
            <div key={item.id + index}>
              <Draggable
                key={item?.id + item?.type}
                draggableId={item?.id ? item.id.toString() : "dragId"}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      cursor: "move", // Change cursor to crosshair
                    }}
                  >
                    <Story
                      isDeleteOpen={deleteOpen}
                      onDeleteOpen={() => setDeleteOpen(true)}
                      onEdit={handleEditStory}
                      story={item.story}
                      workflowData={workflowData}
                    />
                  </div>
                )}
              </Draggable>
            </div>
          );
        }
      })}
      {/* <div className="Context_Footer">
      </div> */}
    </div>
  );
};

export default StoryList;
