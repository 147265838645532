import { createSlice } from "@reduxjs/toolkit";

interface MemberStateProps {
    memberAdded: number;
}

const initialState: MemberStateProps = {
    memberAdded: 0
}

const MemberSlice = createSlice({
    name: "Member",
    initialState,
    reducers: {
        memberAdded: (state) => {
            state.memberAdded++ ; 
        },
        resetMemberAdded: (state) => {
            state.memberAdded-- ; 
        }
    }
})

export const { memberAdded, resetMemberAdded } = MemberSlice.actions
export default MemberSlice.reducer
