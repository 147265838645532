import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import Input from "../Input/Input";
import Button from "../Button/Button";
import StoryTypeList from "./StoryTypeList";
import Textarea from "../TextArea/TextArea";
import getApiKey from "../../config/config";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { storyCreated } from "../../store/Slices/UpdateStorySlice";
import { isValidStoryCreateData } from "../../utils/HelperFunction";
import {
  startCreateStory,
  startUpdateStatusStory,
  startUpdateStory,
} from "../../api/story";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../styles/StoryComp.css";
import { FaChevronUp } from "react-icons/fa";
import Select from "../Select/Select";
import { Workflow, WorkflowState } from "../../interface/WorkflowInterface";
import WorkflowWidget from "../WorkflowWidget";
import "../../styles/StoryTypeList.css";
import { Member } from "../../interface/ProjectInterface";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Comment from "../Comment/Comment";

interface SelectStoryType {
  storyType?: string;
  points: string;
  requester: string;
  owner: string[];
}

interface StoryCompProps {
  handleClose: () => void;
  getContextStory: string;
  stories: any[];
  selectedStory?: any;
  isEdit?: boolean;
  onDeleteStory?: (id: string) => void;
  storyId?: string;
  workflowData?: Workflow[] | null;
  storyModifying?: {
    storyId: string;
    userId: string;
  };
}

function StoryComp(props: StoryCompProps) {
  const {
    handleClose,
    getContextStory,
    stories,
    selectedStory,
    isEdit,
    onDeleteStory,
    storyId,
    workflowData,
  } = props;
  const [description, setDescription] = React.useState("");
  const [select, setSelect] = React.useState<SelectStoryType>({
    storyType: "",
    points: "",
    requester: "",
    owner: [],
  });
  const [storyName, setStoryName] = React.useState<string>("");
  const { id } = useParams();
  const dispatch = useDispatch();
  let workflowRef = React.useRef<WorkflowState[] | undefined>();
  const projectMembers = useSelector(
    (state: RootState) => state.ProjectSlice.projectMember,
  );

  useEffect(() => {
    if (selectedStory && isEdit) {
      setSelect({
        storyType: selectedStory?.type || "",
        owner: selectedStory?.owner || "",
        points: selectedStory?.estimate || "",
        requester: selectedStory?.requestor?.login || "",
      });
    }
    if (workflowData?.length) {
      const workflowDataFilter = workflowData?.filter(
        (item: any) => item.id === selectedStory?.workflow_id,
      );
      workflowRef.current = workflowDataFilter[0].states;
    }
  }, [selectedStory, isEdit, workflowData]);

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  // Create New Story
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const storyIdAfter =
      stories?.length !== 0 ? stories[stories.length - 1] : null;
    const storyId = storyIdAfter ? storyIdAfter.id : null;
    const getApi = getApiKey();
    const userId = getApi?.UserId;
    const requestor_id = projectMembers?.filter(
      (it: Member) => it.login === select.requester,
    );

    const formData = {
      type: select.storyType || "feature",
      estimate: parseInt(select.points, 10),
      requestor_id: requestor_id?.length ? requestor_id[0].id : userId,
      project_id: id,
      description,
      name: storyName,
      story_id_after: storyId || null,
      context_id: getContextStory,
      owner_ids: select.owner,
    };
    if (!isValidStoryCreateData(formData)) {
      return;
    }
    try {
      await startCreateStory(formData);
      dispatch(storyCreated());
      toast.success("Story Created");
      handleClose();
    } catch (err) {
      console.log(`Error in creating story ${err}`);
      toast.error("Error creating story");
    }
  };

  //Update points and Requestor and Owner
  const updatePointsReqOwner = async (updatedData: any) => {
    try {
      await startUpdateStory(selectedStory.id, updatedData);
      dispatch(storyCreated());
    } catch (error) {
      console.log(`Error updating story ${error}`);
      toast.error("Error updating story");
    }
  };

  // handle Select
  const handleSelectChange = async (
    label: string,
    selectedOption: string | number,
  ) => {
    if (label === "points" && selectedOption === "Unestimated") {
      return;
    }
    setSelect({ ...select, [label]: selectedOption });
    if ((label === "points" || label === "requester") && isEdit) {
      if (label === "points") {
        let estimate: number;
        if (typeof selectedOption === "number") {
          // Convert number to string before parsing
          estimate = parseInt(String(selectedOption), 10);
        } else {
          estimate = parseInt(selectedOption, 10);
        }
        updatePointsReqOwner({ estimate });
      } else {
        const requestor_id = projectMembers?.filter(
          (it: Member) => it.login === selectedOption,
        )[0];
        updatePointsReqOwner({ requestor_id: requestor_id?.id });
      }
    }
  };

  const patchStoryOwner = async (updatedStoryOwner: Member[]) => {
    const owner_ids = updatedStoryOwner.map((item) => item.id);
    if (!isEdit) {
      setSelect({ ...select, owner: owner_ids });
    }
    isEdit && updatePointsReqOwner({ owner_ids });
  };

  const handleUpdateStatus = async (status: string, value: string) => {
    try {
      if (selectedStory.estimate === null) {
        toast.info("Select estimation point");
        return;
      }
      const status_id = workflowRef.current?.filter(
        (item) => item.name === value,
      );
      if (status_id?.length) {
        const response = await startUpdateStatusStory(selectedStory?.id, {
          status_id: status_id[0]?.id,
        });
        if (response.success) {
          toast.success("Status Updated successfully");
          // setCurrentState(findStatusById);
          dispatch(storyCreated());
        }
      }
    } catch (error) {
      console.log(`Error updating status ${error}`);
      toast.error("Cannot Update Status");
    }
  };

  const handleCloseStory = () => {
    if (
      isEdit &&
      (storyName.length > 0 || description !== selectedStory.description)
    ) {
      if (storyName.length && description.length) {
        updatePointsReqOwner({ name: storyName, description });
      } else if (storyName.length) {
        updatePointsReqOwner({ name: storyName });
      } else if (description !== selectedStory.description) {
        updatePointsReqOwner({ description });
      }
    }
    handleClose && handleClose();
  };

  return (

    <div className={`StoryComp`} data-testid="StoryComp-ID">
      <form
        onSubmit={handleSubmit}
        className={`StoryComp${
          isEdit &&
          props.storyModifying &&
          props.storyModifying.storyId === selectedStory.id
            ? " blinking-bg"
            : ""
        }`}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          width="100%"
          paddingTop="0.4rem"
        >
          {isEdit && (
            <span
              onClick={() => handleCloseStory()}
              role="button"
              className="StoryComp_span"
            >
              <FaChevronUp />
            </span>
          )}
          <Box width="100%">
            <Input
              defaultValue={selectedStory?.name}
              placeholder="Title"
              onValueChange={(value: string) => setStoryName(value)}
            />
          </Box>
        </Stack>

        <div className="StoryComp_buttons">
          <Button label="cancel" onClick={handleCloseStory} />
          {!isEdit && <Button label="save" isPrimary type="submit" />}
          {isEdit && (
            <span
              className="StoryComp_span"
              role="button"
              onClick={() => {
                if (storyId && isEdit) {
                  onDeleteStory && onDeleteStory(storyId);
                }
              }}
            >
              <DeleteIcon />
            </span>
          )}
        </div>

        <Stack className="StoryComp_Stack_Main">
          <StoryTypeList
            selectedStoryType={select}
            onChange={handleSelectChange}
            isEdit={isEdit}
            patchStoryOwner={patchStoryOwner}
            selectedStory={selectedStory}
          />
          <Stack direction="row" alignItems="flex-start">
            {isEdit && (
              <Stack className="StoryComp_Stack" direction={"row"}>
                <p className="story-comp__state-text">State</p>
                <Box className="StoryComp_Select">
                  <WorkflowWidget
                    story={selectedStory}
                    workflowData={workflowData}
                  />
                  <Select
                    defaultSelect={selectedStory.status.name}
                    onChange={(label, value) =>
                      handleUpdateStatus(selectedStory.status.name, value)
                    }
                    placeholder=""
                    options={workflowRef.current?.map((item) => item.name)}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
        <div>
          <Textarea
            defaultValue={selectedStory?.description}
            placeholder="Add Description"
            onChange={handleDescriptionChange}
          />
        </div>
        <div>
          {
            isEdit && <Comment selectedStory= {selectedStory} />
          }
        </div>
      </form>
    </div>
  );
}
export default StoryComp;
