import getApiKey from "../config/config";
import { Config } from "./_config";
import { initializeAxios } from "./_main";
const { comment } = Config;


export const startCreateComment = (data: any) => {
    const path = comment.root;
  
    const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);
  
    return new Promise<{ success: boolean; body?: any; message?: string }>(
      (resolve, reject) => {
        axiosClient
          .post(path, data)
          .then((res) => {
            resolve({
              success: true,
              body: res.data,
              message: res.data?.message || "comment created successfully",
            });
          })
          .catch((error) => {
            reject({ success: false, message: error.message });
          });
      },
    );
  };

  
export const getComment = (storyId : string) => {
    const path = comment.root + comment.story_id + `${storyId}`;
  
    const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);
  
    return new Promise<{ success: boolean; body?: any; message?: string }>(
      (resolve, reject) => {
        axiosClient
          .get(path)
          .then((res) => {
            resolve({
              success: true,
              body: res.data,
              message: res.data?.message || "comment fetched successfully",
            });
          })
          .catch((error) => {
            reject({ success: false, message: error.message });
          });
      },
    );
  };


  export const updateComment = ( comment_id: string, data: any) => {
    const path = comment.root + `/${comment_id}`;
  
    const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);
  
    return new Promise<{ success: boolean; body?: any; message?: string }>(
      (resolve, reject) => {
        axiosClient
          .patch(path, data)
          .then((res) => {
            resolve({
              success: true,
              body: res.data,
              message: res.data?.message || "comment updated successfully",
            });
          })
          .catch((error) => {
            reject({ success: false, message: error.message });
          });
      },
    );
  };

  export const deleteComment = (comment_id: string) => {
  const path = comment.root + `/${comment_id}`;

  const axiosClient = initializeAxios(Config.baseURL, getApiKey().API_KEY);

  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .delete(path)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "comment deleted successfully",
          });
        })
        .catch((error) => {
          console.error('Error deleting comment:', error.response || error.message || error)
          reject({ success: false, message: error.message });
        });
    }
  );
};
