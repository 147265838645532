import React, { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login, logout } from '../store/Slices/AuthSlice'
import { useNavigate, Navigate } from 'react-router-dom'

interface AuthContextProps {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)



export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated,
  )
  const navigate = useNavigate()

  useEffect(() => {
    const token = sessionStorage.getItem('token')

    if (token) {
      dispatch(login())
    }
  }, [dispatch, navigate])

  const loginHandler = () => {
    // Perform login actions (replace with actual login logic)
    sessionStorage.setItem('token', 'yourtoken')
    dispatch(login())
  }

  const logoutHandler = () => {
    // Perform logout actions (replace with actual logout logic)
    sessionStorage.removeItem('token')
    dispatch(logout())
  }

  const storageData = sessionStorage.getItem('token')

  if (storageData !== null ) {
    return (
      <AuthContext.Provider
        value={{ isAuthenticated, login: loginHandler, logout: logoutHandler }}
      >
        {children}
      </AuthContext.Provider>
    )
  } else {
    return <Navigate to="/login" />
  }
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
