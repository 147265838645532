/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../../styles/Tracker.css";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Context from "../../components/Context/Context";
import SideBar from "../../components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { storyCreated } from "../../store/Slices/UpdateStorySlice";
// import { StoryLink } from "../../interface/StoryInterface";
import useFetchContext from "../../hooks/useFetchContext";
import { ProjectMembers } from "../../store/Slices/ProjectSlice";
import { getProjectById } from "../../api/ProjectAPIs";
import { storyDragAndDrop } from "../../api/contextStory";
import { searchKeyInUpdatedData } from "../../utils/CommonFunction";
import { updateData } from "../../store/Slices/AllContextStoryData";
import useSocket from "../../hooks/useSocket";
import { useParams } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { SOCKET_EVENT_COMMAND } from "../../utils/Constant";
import { updatedList } from "../../store/Slices/MultiUserUpdate";

function Tracker() {
  // const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [storyModifying, setModification] = React.useState<
    | {
        storyId: string;
        userId: string;
      }
    | any
  >(null);
  const [displayContext, setDisplayContext] = React.useState<any[]>([]);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const updatedData = useSelector((state: RootState) => state.AllContextStory);
  const { id } = useParams();
  const [fetchContext, setFetchContext] = useFetchContext();
  const memberAdded = useSelector((state: RootState) => state.MemberSlice);

  //Listen "messages" topic
  const { data: dataMessages } = useSocket({ topic: "messages" });
  const { data: dataMessagesUpdate } = useSocket({
    topic: "update_project_member",
  });

  const { updatedMemberlist } = useSelector(
    (state: RootState) => state.MultiUserSlice,
  );

  React.useEffect(() => {
    // TODO: handle by command type
    if (!dataMessagesUpdate) {
      return;
    }
    if (dataMessagesUpdate) {
      dispatch(updatedList(dataMessagesUpdate.members));
    }
  }, [dataMessagesUpdate, dispatch]);

  React.useEffect(() => {
    // TODO: handle by command type
    console.log("tracker messages", dataMessages);
    if (!dataMessages) {
      return;
    }
    switch (dataMessages.command) {
      case SOCKET_EVENT_COMMAND.STORY_MODIFYING:
        setModification(dataMessages.data);
        break;
      case SOCKET_EVENT_COMMAND.STORY_CLOSE_MODIFYING:
        setModification(null);
        break;

      default:
        dispatch(storyCreated());
        break;
    }
  }, [dataMessages, dispatch]);

  React.useEffect(() => {
    const fetchProjectById = async () => {
      try {
        const response = await getProjectById(id!);
        dispatch(ProjectMembers(response?.body.members));
      } catch (error) {
        toast.error("Cannot fetch project by Id");
      }
    };
    fetchProjectById();
  }, [id, dispatch, memberAdded, updatedMemberlist]);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (destination === null || destination === undefined) {
      return;
    }
    const dropContextId = destination?.droppableId
      ? destination?.droppableId
      : undefined;
    if (
      dropContextId === undefined ||
      (dropContextId === source.droppableId &&
        source.index === destination?.index)
    ) {
      return;
    }

    const keyExist = searchKeyInUpdatedData(updatedData, dropContextId);

    let prevStoryId: string | null = null;
    let nextStoryId: string | null = null;

    const traverArray = keyExist.filter(
      (it, index) => index === destination?.index,
    );

    if (dropContextId === source.droppableId) {
      if (
        destination?.index !== undefined &&
        source.index < destination?.index
      ) {
        prevStoryId = traverArray[0].id;
        nextStoryId = traverArray[0].next_story_id;
      } else if (
        destination?.index !== undefined &&
        source.index > destination?.index
      ) {
        prevStoryId = traverArray[0].previous_story_id;
        nextStoryId = traverArray[0].id;
      }
    } else {
      if (!keyExist.length) {
        prevStoryId = null;
        nextStoryId = null;
      } else if (destination?.index !== undefined && traverArray.length === 0) {
        nextStoryId = null;
        prevStoryId = keyExist[keyExist.length - 1].id;
      } else if (destination?.index !== undefined) {
        prevStoryId = traverArray[0].previous_story_id;
        nextStoryId = traverArray[0].id;
      }
    }

    // update the temporary story in the updatedData for fixing Ui flickering
    const draggedContextData = searchKeyInUpdatedData(
      updatedData,
      source.droppableId,
    );
    const draggedStory = draggedContextData.filter(
      (it) => it.id === result.draggableId,
    )[0];
    if (
      draggedStory.story.status.name !== "Unstarted" &&
      dropContextId !== source.droppableId
    ) {
      return;
    }
    const addNewStory = [...keyExist];
    if (dropContextId === source.droppableId) {
      addNewStory.splice(source.index, 1);
    } else {
      const removeDraggedStory = [...draggedContextData];
      removeDraggedStory.splice(source.index, 1);
      dispatch(
        updateData({ key: source.droppableId, value: removeDraggedStory }),
      );
    }
    addNewStory.splice(destination.index, 0, draggedStory);

    dispatch(updateData({ key: destination.droppableId, value: addNewStory }));

    try {
      const data = {
        context_id: destination?.droppableId,
        previous_story_id: prevStoryId,
        next_story_id: nextStoryId,
      };

      await storyDragAndDrop(result.draggableId, data);
      dispatch(storyCreated());
    } catch (error: any) {
      toast.error("Error dropping story");
      console.log(`Error while drag and drop ${error}`);
    }
  };

  const handleCloseContext = (contextId: string) => {
    setDisplayContext((prevContexts) =>
      prevContexts.filter((context) => context.id !== contextId),
    );
    const removeContextIndex = fetchContext.findIndex(
      (obj: any) => obj.id === contextId,
    );
    const updatedMenuItems = [...fetchContext];
    updatedMenuItems[removeContextIndex].selected =
      !updatedMenuItems[removeContextIndex].selected;
    setFetchContext(updatedMenuItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div data-testid="Tracker-Id">
        <NavBar />
        <div className="App_Menu">
          <div>
            <SideBar
              collapsed={collapsed}
              handleCollapsedChange={handleCollapsedChange}
              displayContext={displayContext}
              setDisplayContext={setDisplayContext}
              fetchContext={fetchContext}
              setFetchContext={setFetchContext}
            />
          </div>
          <div className="App_Outlets">
            {displayContext.map((item, index) => {
              return (
                <Droppable droppableId={item.id} key={item.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ width: "100%" }}
                    >
                      <Context
                        storyModifying={storyModifying}
                        label={item.name}
                        icon={item.icon}
                        getContextStory={item.id}
                        onClose={() => handleCloseContext(item.id)}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
export default Tracker;
