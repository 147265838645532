// storySlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../config/config';

interface StoryState {
  data: any[]; // Adjust the type according to your API response
  loading: boolean;
  error: string | null;
}

interface FetchStoriesParams {
  id: string | undefined;
  dropContextId: string | undefined;
  apiKey: string;
}


// Async thunk to fetch stories
export const fetchStories = createAsyncThunk<
  any[], // Adjust the return type according to your API response
  FetchStoriesParams,
  { rejectValue: string }
>(
  'stories/fetchStories',
  async ({ id, dropContextId, apiKey }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/project/${id}/context/${dropContextId}/story`,
        {
          headers: {
            'X-API-Key': apiKey,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('Error fetching stories');
    }
  }
);

// Slice
const storySlice = createSlice({
  name: 'stories',
  initialState: {
    data: [],
    loading: false,
    error: null,
  } as StoryState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStories.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchStories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default storySlice.reducer;
