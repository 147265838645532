export const searchKeyInUpdatedData = (updatedData: any, keyToSearch: string | undefined): any[] => {
  // Check if the keyToSearch exists in the updatedData object
  if (Object.keys(updatedData).includes(keyToSearch!)) {
    return updatedData[keyToSearch!]; // Return the value of the key if it exists
  } else {
    return []; // Return null if the key does not exist
  }
};


export const capitalizedName = (value: string) : string => {
  const name = value.charAt(0).toUpperCase() + value.slice(1);
  return name ; 
}