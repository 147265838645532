import React from "react";
import "../../styles/ProjectMenu.css";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import { FiberManualRecordRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropDown from "../DropDown/DropDown";
import ProjectModel from "../../models/ProjectModel";
import useProjectFetch from '../../hooks/useProjectFetch';
import { useParams } from "react-router-dom";

const ProjectMenu: React.FC = () => {
  const navigate = useNavigate();
  const projectList = useProjectFetch()
  const { id } = useParams();

  React.useEffect(() => {
  }, [projectList])

  const { t } = useTranslation() ; 

  const goToProjectList = () => {
    navigate("/project-list");
  }

  const goToProject = (id: string) => {
    navigate(`/project/${id}`);
  }

if (!id) {
    return null;
}
if (!projectList) {
    return null;
}

const project = projectList.find((projectEntry: any) => projectEntry.id === id) as unknown as ProjectModel;
if (!project) {
    return null;
}
let name = project.name;

  return (
    <div className="DropDown" data-testid="project-menu" style={{
        marginRight: '20px',     
    }}>
        <DropDown label={name}>
        <div className="ProjectMenu" data-testid="user-menu">
            <div className="ProjectMenu_Profile" onClick={goToProjectList}>
                <ListAltRoundedIcon fontSize="small" className="" /> <p>{t("projectList") }</p>
            </div>
            {projectList.length > 1 && (
              <div className="DropDown_separator" />
            )}
            {projectList.map((projectEntry: any) => {
                if (id === projectEntry.id) {
                    return null;
                }
                return <div className="ProjectMenu_Profile" key={projectEntry.id} onClick={() => goToProject(projectEntry.id)}>
                    <FiberManualRecordRounded fontSize="small" className="" /> <p>{projectEntry.name}</p>
                </div>
            }
        )}
            
        </div>
        </DropDown>
    </div>
  );
};

export default ProjectMenu;
