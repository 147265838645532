import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
  color?: string;
  size?: number;
}

export default function Loader({ color = '#37728b' , size = 40 }: LoaderProps){
  return (
    <Box sx={{ display: 'flex', alignSelf: "center" }} data-testid="Loader-ID">
      <CircularProgress sx={{ color: color }} size={size}  />
    </Box>
  );
}