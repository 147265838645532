import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import "../../styles/Project.css";
import ProjectList from "./ProjectList";
import DialogBox from "../../components/DialogBox/DialogBox";
import CreateProject from "./CreateProject";
import ImportStories from "../../components/Import/importStories";

function Project() {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="ProjectPage" data-testid="Project-ID">
      <h1>{t("projects")}</h1>
      <div className="Project-Buttons">
        <Button
          label={t("createProject")}
          isPrimary={true}
          onClick={() => setDialogOpen(true)}
        />
        <ImportStories />
      </div>
      {dialogOpen && (
        <DialogBox
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          title="createProject"
        >
          <CreateProject setDialogOpen={setDialogOpen} />
        </DialogBox>
      )}
      <ProjectList />
    </div>
  );
}

export default Project;

// .DialogBox_Button .MuiSvgIcon-root {
//      position: absolute;
//     right: 8px;
//     bottom: 2.5rem;
// }
