import React from "react";
import "../../styles/MemberList.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getProjectById, removeMemberfromProject } from "../../api/ProjectAPIs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { memberAdded } from "../../store/Slices/MemberSlice";
import { ProjectMembers } from "../../store/Slices/ProjectSlice";
import { getUser } from "../../config/config";
import useSocket from "../../hooks/useSocket";
import {
  resetUpdatedList,
  updatedList,
} from "../../store/Slices/MultiUserUpdate";

interface MemberListProps {
  userSearch: string;
}

function MemberList({ userSearch }: MemberListProps) {
  const [optionOpen, setOptionOpen] = React.useState<string>("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const addedMember = useSelector(
    (root: RootState) => root.MemberSlice.memberAdded,
  );
  const userId = getUser();
  const [toogleList, setToogleList] = React.useState<boolean>(false);

  const getInitials = (name: string) => {
    const nameArray = name.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };
  const projectMember = useSelector(
    (state: RootState) => state.ProjectSlice.projectMember,
  );
  const { updatedMemberlist } = useSelector(
    (state: RootState) => state.MultiUserSlice,
  );
  const listRef = React.useRef<HTMLUListElement>(null);
  const { data: dataMessages } = useSocket({ topic: "update_project_member" });

  React.useEffect(() => {
    // TODO: handle by command type
    if (!dataMessages) {
      return;
    }
    if (dataMessages) {
      const messageLength: [] = dataMessages.members;
      if (updatedMemberlist.length === messageLength.length) {
        dispatch(resetUpdatedList());
      } else dispatch(updatedList(dataMessages.members));
    }
    // eslint-disable-next-line
  }, [dataMessages, dispatch]);

  React.useEffect(() => {
    const fetchProjectById = async () => {
      try {
        const response = await getProjectById(id!);
        dispatch(ProjectMembers(response?.body.members));
      } catch (error) {
        console.log(`Error fetching project by id ${error}`);
        toast.error("Cannot fetch project by Id");
      }
    };
    fetchProjectById();
  }, [id, dispatch, addedMember, updatedMemberlist]);

  const getCapital = (name: string) => {
    const remainingString = name.substring(1, name.length);
    return name.charAt(0).toUpperCase() + remainingString;
  };

  const handleRemoveFromProject = async (member_id: string) => {
    setOptionOpen("");
    try {
      await removeMemberfromProject(id!, { member_id });
      dispatch(memberAdded());
      dispatch(resetUpdatedList());
    } catch (error) {
      console.error(`Error Removing Data`);
      toast.error(`${error}`);
    }
  };

  const handleClick = (id: string) => {
    setOptionOpen(id);
    setToogleList(!toogleList);
  };

  const projectMemberList = React.useMemo(() => {
    if (userSearch) {
      const matchingUsers = projectMember!.filter(
        (user) =>
          user.login.toLowerCase().includes(userSearch) ||
          user.given_name.toLowerCase().includes(userSearch) ||
          user.family_name.toLowerCase().includes(userSearch) ||
          user.email.toLowerCase().includes(userSearch),
      );
      return matchingUsers;
    }
    return projectMember;
  }, [projectMember, userSearch]);

  return (
    <div className="MemberList">
      {projectMemberList?.map((it) => {
        return (
          <React.Fragment key={it.id + it.login}>
            <div className="MemberList_Main">
              <div className="MemberList_data">
                <div className="MemberList_info">
                  <div className="MemberList_initials">
                    {getInitials(`${it.given_name} ${it.family_name}`)}
                  </div>
                  <div className="MemberList_name">
                    <p>
                      {getCapital(it.given_name) +
                        " " +
                        getCapital(it.family_name)}
                    </p>
                    <p>{it.email}</p>
                  </div>
                </div>

                {userId.id !== it.id ? (
                  <div className="MemberList_btn">
                    <button onClick={() => handleClick(it.id)}>
                      <MoreHorizIcon />
                    </button>
                    {toogleList && optionOpen === it.id && (
                      <div className="OptionOverlay">
                        <ul className="ListOfOption" ref={listRef}>
                          <li onClick={() => handleRemoveFromProject(it.id)}>
                            Remove
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <hr style={{ marginTop: "2%" }} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default MemberList;
