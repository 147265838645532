import React from "react";
import Button from "../../components/Button/Button";
import {
  Avatar,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { memberAdded } from "../../store/Slices/MemberSlice";
import { toast } from "react-toastify";
import "../../styles/AddMember.css";
import Input from "../../components/Input/Input";
import { addMemberToProject } from "../../api/ProjectAPIs";
import { fetchAllUsers } from "../../api/user";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

interface AddMemberProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserData {
  id: string;
  given_name: string;
  family_name: string;
  login: string;
  email: string;
  created_at: string;
  updated_at: string;
}

function AddMember({ setDialogOpen }: AddMemberProps) {
  const dispatch = useDispatch();
  const [memberName, setMemberName] = React.useState<string>("");
  const { id } = useParams();
  const [allUserData, setAllUserData] = React.useState<UserData[]>([]);
  const [displaySearchResults, setDisplaySearchResults] = React.useState<
    UserData[]
  >([]);
  const [selectedUser, setSelectedUser] = React.useState<UserData[]>([]);
  const [selectedRole, setSelectedRole] = React.useState<string>("Member");
  const { t } = useTranslation();

  const handleSelectedUser = (user: any) => {
    setDisplaySearchResults([]);
    setMemberName("");
    if (selectedUser.length === 1) return;
    setSelectedUser([...selectedUser, user]);
  };

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchAllUsers(true);
        setAllUserData(response.body.rows);
      } catch (error: any) {
        console.error(`Error fetching all User ${error}`);
        toast.error("Error Fetching All User");
      }
    };
    fetchUser();
  }, []);

  const handleValue = (value: string) => {
    const lowerCaseValue = value.toLowerCase();
    setMemberName(value);
    const matchingUsers = allUserData.filter(
      (user) =>
        user.login.toLowerCase().includes(lowerCaseValue) ||
        user.given_name.toLowerCase().includes(lowerCaseValue) ||
        user.family_name.toLowerCase().includes(lowerCaseValue) ||
        user.email.toLowerCase().includes(lowerCaseValue),
    );
    if (value !== "") {
      setDisplaySearchResults(matchingUsers);
    } else {
      setDisplaySearchResults([]);
    }
  };

  const handleAddMember = async () => {
    try {
      if (selectedUser.length === 0) {
        toast.error("Select Member");
        return;
      }
      await addMemberToProject(id!, {
        member_id: selectedUser[0].id,
        role: selectedRole.toLowerCase(),
      });
      setDialogOpen(false);
      setMemberName("");
      dispatch(memberAdded());
    } catch (error: any) {
      console.error(`Error added member ${error}`);
      toast.error("Cannot Add Member");
    }
  };

  return (
    <div className="dialogbox-container">
      <DialogContent className="dialog-Content" dividers>
        <div className="input-container">
          <Input
            placeholder="Find by name or email"
            withSearch={true}
            defaultValue={memberName}
            onValueChange={handleValue}
            autoComplete="off"
          />
          <div className="input-container-overlay">
            {displaySearchResults.map((user: UserData, index: number) => {
              const capitalizedGivenName =
                user.given_name.charAt(0).toUpperCase() +
                user.given_name.slice(1);
              const capitalizedFamilyName =
                user.family_name.charAt(0).toUpperCase() +
                user.family_name.slice(1);
              return (
                <div
                  className="displaying-user-list"
                  key={user.login + user.given_name}
                  onClick={() => handleSelectedUser(user)}
                >
                  <Avatar
                    key={user.id + index}
                    alt={user.login}
                    sx={{ width: 36, height: 36, ml: "10px" }}
                  >
                    {user?.given_name[0]}
                  </Avatar>
                  <p>{capitalizedGivenName + " " + capitalizedFamilyName}</p>
                </div>
              );
            })}
          </div>
        </div>
        {selectedUser.length ? (
          <div className="Check-Member-List">
            {selectedUser.map((user: any, index: number) => {
              const capitalizedGivenName =
                user.given_name.charAt(0).toUpperCase() +
                user.given_name.slice(1);
              const capitalizedFamilyName =
                user.family_name.charAt(0).toUpperCase() +
                user.family_name.slice(1);
              return (
                <div
                  className="selected-user-list"
                  key={user.login + user.login}
                >
                  <Avatar
                    key={user.id + index}
                    alt={user.login}
                    sx={{ width: 36, height: 36, ml: "10px" }}
                  >
                    {user?.given_name[0]}
                  </Avatar>
                  <p>{capitalizedGivenName + " " + capitalizedFamilyName}</p>
                  <div className="User-type">
                    <select
                      name=""
                      id="simple-select-list"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    >
                      <option value="member">Member</option>
                      <option value="viewer">Viewer</option>
                      <option value="owner">Owner</option>
                    </select>
                  </div>
                  <IconButton
                    aria-label="close"
                    onClick={() => setSelectedUser([])}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div className="info">
              <p>{t("SearchUser")}</p>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={selectedUser.length === 0}
          label="Invite"
          isPrimary={true}
          onClick={handleAddMember}
        />
      </DialogActions>
    </div>
  );
}

export default AddMember;
