/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { initSocket } from "../utils/Socket";
import getApiKey from "../config/config";

const useSocket = ({ topic }: { topic: string }) => {
  const getKey = getApiKey();
  const API_KEY = getKey?.API_KEY;
  const [data, setData] = useState<any>();
  const [isConnected, setConnected] = useState(false);
  const socket = initSocket(API_KEY);

  useEffect(() => {
    if (!topic || !socket) return;
    socket?.on("connect", () => {
      console.log("socket connected");
      setConnected(true);
    });
    socket?.on("disconnect", () => {
      console.log("socket disconnect");
      setConnected(false);
    });
  }, [API_KEY, socket]);

  useEffect(() => {
    if (!topic || !socket) return;

    const receiveMessage = (data: any) => {
      setData(data);
    };
    socket.on(topic, receiveMessage);

    return () => {
      socket.off(topic, receiveMessage);
    };
  }, [topic, socket]);

  const emit = (event: string, data: any) => {
    socket?.emit(event, data);
  };

  return { data, isConnected, emit };
};

export default useSocket;
