import { createSelector, createSlice } from "@reduxjs/toolkit";

interface CreateStorySliceProps {
  counter: number;
}

const initialState: CreateStorySliceProps = {
  counter: 0,
};

const CreateStorySlice = createSlice({
  name: "CreateStory",
  initialState,
  reducers: {
    storyCreated: (state) => {
      state.counter += 1;
    },

    storyDeleted: (state) => {
      state.counter = state.counter + 1;
    },

    resetStoryCounter: (state) => {
      state.counter = 0;
    },
  },
});

export const { storyCreated, storyDeleted, resetStoryCounter } =
  CreateStorySlice.actions;
export default CreateStorySlice.reducer;


const getStoryState = (rootState: any): CreateStorySliceProps =>
  rootState["CreateStory"];

export const selectStoryCounter = createSelector(
  getStoryState,
  (s) => s.counter,
);
