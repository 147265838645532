import getApiKey from "../config/config";
import { Config } from "./_config";
// import axiosClient from "./_main";
import { initializeAxios } from "./_main";

const { project, baseURL } = Config;

export const getProjectById = (id: string) => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path = project.root + project.get + `/${id}`;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .get(path)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "Project by Id fetched successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const getAllProject = () => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path = project.root + project.get;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .get(path)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message: res.data?.message || "Project fetched successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const getStoryInProjectContext = (
  project_id: string,
  context_id: string,
) => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path =
    project.root +
    `/${project_id}` +
    project.context +
    `/${context_id}` +
    project.story;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .get(path)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message:
              res.data?.message ||
              "Story in project context  fetched successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const addMemberToProject = (id: string, data: any) => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path = project.root + `/${id}` + project.addMember;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .patch(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message:
              res.data?.message || "Added member to project successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const removeMemberfromProject = (project_id: string, data: any) => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path = project.root + `/${project_id}` + project.removeMember;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .patch(path, data)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message:
              res.data?.message || "Added member to project successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};

export const importProject = (formData: FormData) => {
  const axiosClient = initializeAxios(baseURL, getApiKey().API_KEY);

  const path = project.root + project.createWithImport;
  return new Promise<{ success: boolean; body?: any; message?: string }>(
    (resolve, reject) => {
      axiosClient
        .post(path, formData)
        .then((res) => {
          resolve({
            success: true,
            body: res.data,
            message:
              res.data?.message ||
              "Added new project by import is successfully",
          });
        })
        .catch((error) => {
          reject({ success: false, message: error.message });
        });
    },
  );
};
