import {createSlice } from "@reduxjs/toolkit";


interface AuthState{
    isAuthenticated : boolean; 
    apiKey : string
}

const initialState: AuthState = {
    isAuthenticated: false, 
    apiKey : ""
}

const authSlice = createSlice({
    name: 'auth', 
    initialState, 
    reducers:{
        login : (state) => {
            state.isAuthenticated = true
        }, 
        logout  : (state) => {
            state.isAuthenticated = false
        }

    }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;